import { z } from 'zod'
import {
  listingBalanceSchema,
  listingPurchaseSchema
} from '@/api/resources/user/listing/purchase/shared'

export const purchaseListingStartSchema = z.object({
  reservation_id: z.string(),
  buyer_address: z.string(),
  seller_address: z.string(),
  buyer_token_address: z.string(),
  seller_token_address: z.string(),
  balance: listingBalanceSchema,
  listing: listingPurchaseSchema,
  solana_priority_rate: z.number()
})

export const purchaseListingStartResponseSchema = purchaseListingStartSchema

export type PurchaseListingStart = z.infer<typeof purchaseListingStartSchema>

export type PurchaseListingStartResponse = z.infer<
  typeof purchaseListingStartResponseSchema
>
