import { classNames } from '@/util/tailwindHelpers'
import { ButtonLink, IButtonLink } from '../Button'

const SectionLink = ({ className = '', children, ...rest }: IButtonLink) => {
  return (
    <ButtonLink
      {...rest}
      theme="clean-blue"
      className={classNames(className, 'md:text-base text-center')}
    >
      {children}
    </ButtonLink>
  )
}

export default SectionLink
