import { useForm } from 'react-hook-form'
import NiceModal from '@ebay/nice-modal-react'
import { MODAL_ID } from '@/constants/modalId'
import Button from '@/components/Button'
import Header from './components/Header'
import Amount from './components/Amount'
import Parties, { TransactionParty } from './components/Parties'
import Web3TransactionModal, { Web3Transaction } from '.'
import { hideModal } from '@/components/Modals/ModalV2'
import { notifyWalletError } from '@/features/wallet/utils/notify'
import { useWallet } from '@/features/wallet/providers/wallet'

export type WithdrawalIntent = {
  amount: number
  from: TransactionParty
  to: TransactionParty
}

interface Web3Withdrawal extends Web3Transaction {
  withdrawalIntent: WithdrawalIntent
  onApproved: (credentialBundle: string) => void
}

const WithdrawalForm = ({
  ModalBody,
  ModalFooter,
  onApproved,
  withdrawalIntent
}) => {
  const { resolveCredentials } = useWallet()
  const { amount, from, to } = withdrawalIntent

  const {
    handleSubmit,
    formState: { isSubmitting }
  } = useForm()

  const onSubmit = async () => {
    try {
      const credentialBundle = await resolveCredentials()
      hideModal(MODAL_ID.web3.approveWithdrawal)
      onApproved(credentialBundle)
    } catch (error) {
      notifyWalletError(error)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalBody>
        <div className="flex flex-col gap-3">
          <Header description="Please approve the request to complete the withdrawal." />
          <div className="space-y-[10px]">
            <Amount amount={amount} />
            <Parties parties={{ from, to }} />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          type="submit"
          className="w-full"
          theme="solana"
          loading={isSubmitting}
        >
          Approve Withdrawal
        </Button>
      </ModalFooter>
    </form>
  )
}

const Web3WithdrawalApproval = NiceModal.create<Web3Withdrawal>(modalProps => {
  return (
    <Web3TransactionModal id={MODAL_ID.web3.approveWithdrawal}>
      {layoutProps => <WithdrawalForm {...layoutProps} {...modalProps} />}
    </Web3TransactionModal>
  )
})

export default Web3WithdrawalApproval
