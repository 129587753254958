import * as Sentry from '@sentry/nextjs'
import {
  ExportWalletError,
  InvalidPasskeyError,
  InvalidRecoveryCodeError,
  PasskeyCreationError,
  WalletError
} from '@/features/wallet/types'
import { Toast } from '@/components'
import { logSessionEvent } from '@/util/logger'

export const notifyWalletConnected = () => {
  Toast({
    type: 'success',
    autoClose: 5000,
    description: 'Wallet connected.'
  })
}

const notifyError = (msg: string, title?: string) =>
  Toast({
    type: 'warning',
    autoClose: 3000,
    title: title,
    description: msg
  })

export const captureWalletError = (err: unknown, overrideHints = {}) => {
  const defaultHints = {
    tags: {
      feature: 'wallet'
    }
  }

  const hints = { ...defaultHints, ...overrideHints }
  Sentry.captureException(err, hints)
}

export const notifyWalletError = (err: unknown) => {
  if (err instanceof Error) {
    const cause = err instanceof WalletError ? err.cause : err
    captureWalletError(cause)
    logSessionEvent(`[wallet][error] ${cause.message}`)
  }

  if (err instanceof PasskeyCreationError)
    return notifyError(err.cause.message, 'Passkey Creation Failed')

  if (err instanceof InvalidPasskeyError)
    return notifyError(
      'The passkey you entered doesn’t match the one saved for your Droppp Wallet on this device and browser. If you have multiple passkeys, please try another one.',
      'Invalid Passkey'
    )

  if (err instanceof InvalidRecoveryCodeError)
    return notifyError(
      'Please open the provided link on the same device and browser that initiated the reconnect process within 10 minutes of starting.',
      'Invalid or Expired Link'
    )

  if (err instanceof ExportWalletError) {
    return notifyError(
      'We encountered an issue retrieving your seed phrase. Please try showing it again.',
      'Please Try Again'
    )
  }

  const msg =
    err instanceof Error ? err?.message : 'There was an unexpected error'

  notifyError(msg, 'Error')
}
