import React from 'react'
import { dateToLocal } from '@/util/timeHelpers'
import { Separator } from '@/components'
import DetailHeader from '@/components/ActivityDetail/DetailHeader'
import PurchaseItems from '@/components/ActivityDetail/PurchaseItems'
import { ActionDetailProps } from '..'
import ActivityStatusBadge from '@/components/Activity/ActivityStatusBadge'
import { USDC } from '@/components/USDC'
import { getMarketplaceActionPaymentSourceLabel } from '@/util/activityHelpers'

const MarketplacePurchase = ({ activity }: ActionDetailProps) => {
  const { item_id, listing, time_created, info, tx_chain_status } = activity
  const { seller_account, asset, listing_price, payment_source } = listing
  const { mint_num, media } = asset
  const paymentSource = getMarketplaceActionPaymentSourceLabel(payment_source)

  const details = [
    {
      label: 'Status',
      value: <ActivityStatusBadge status={tx_chain_status} />
    },
    {
      label: 'Date',
      value: dateToLocal({ date: time_created })
    },
    {
      label: 'Transaction ID',
      value: `DP${item_id}`
    },
    {
      label: 'Seller',
      value: seller_account
    }
  ]

  // In the instance of a failed transation, the payment source could be null
  if (paymentSource) {
    details.splice(2, 0, {
      label: 'Payment Method',
      value: paymentSource
    })
  }

  // Only one marketplace place item can be purchased at a time
  const item = [
    {
      name: info,
      detail: `#${mint_num}`,
      media,
      price: (
        <USDC
          price={listing_price}
          className="pricing font-normal"
          suffixClassName="pricing-sm"
        />
      )
    }
  ]

  return (
    <>
      <DetailHeader details={details} />
      <Separator className="my-3" />
      <PurchaseItems
        items={item}
        total={<USDC price={listing_price} suffixClassName="pricing-sm" />}
        status={tx_chain_status}
        pendingMessage="This purchase is pending confirmation on the blockchain. It will
        be available in your inventory once the sale completes."
      />
    </>
  )
}

export default MarketplacePurchase
