import { DROP_HERO_TYPES } from '@/enum'
import DropMonstersHero from './DropMonstersHero'
import DropMonstersLandingHero from './DropMonstersLandingHero'
import DropStandardHero from './DropStandardHero'
import DropStandardLandingHero from './DropStandardLandingHero'
import { initialPackSaleEndedWithinSevenDays } from '@/util/timeHelpers'
import DropCondensedHero from './DropCondensedHero'
import { isDropLaunched, isRewardsDrop } from '@/util/dropHelpers'
import useBreakpoints from '@/hooks/useBreakpoints'
import {
  DropProvider,
  useDropContext
} from '@/features/drop/DropContextProvider'

interface IDropHeroFactory {
  drop: any
  order?: number
  type?: string
}

const DropHeroFactory = ({
  drop,
  order,
  type = DROP_HERO_TYPES.STANDARD
}: IDropHeroFactory) => {
  return (
    <DropProvider drop={drop}>
      <HeroComponent order={order} type={type} />
    </DropProvider>
  )
}

export default DropHeroFactory

type Props = {
  order?: number
  type?: string
}
const HeroComponent = ({ order, type = DROP_HERO_TYPES.STANDARD }: Props) => {
  const { drop, time_launch } = useDropContext()
  const { isLarge } = useBreakpoints()
  if (type === DROP_HERO_TYPES.STANDARD) {
    switch (drop.dropHeroComponent) {
      case 'DropMonstersHero':
        return <DropMonstersHero />
      default:
        if (
          isRewardsDrop(drop) &&
          isDropLaunched(drop) &&
          initialPackSaleEndedWithinSevenDays(time_launch) &&
          isLarge
        ) {
          return <DropCondensedHero spacing={order === 0} />
        } else {
          return <DropStandardHero />
        }
    }
  } else {
    switch (drop.dropLandingHeroComponent) {
      case 'DropMonstersLandingHero':
        return <DropMonstersLandingHero />
      default:
        return <DropStandardLandingHero />
    }
  }
}
