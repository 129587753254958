import { tv } from 'tailwind-variants'
import { classNames } from '@/util/tailwindHelpers'
import Link, { LinkProps } from 'next/link'
import { HTMLAttributeAnchorTarget, ReactNode } from 'react'
import { scrollIntoViewWithOffset } from '@/util/scrollHelpers'
import Spinner from '@/components/Spinner'

export type ButtonSize = 'lg' | 'md' | 'sm' | 'xs' | 'auto'
const ButtonThemes = [
  'blue',
  'white',
  'gray',
  'gray-transparent',
  'black',
  'blocked',
  'green',
  'rainbow',
  'clean',
  'clean-blue',
  'secondary',
  'destructive',
  'gray-blur',
  'green-cta',
  'solana'
] as const
export type ButtonTheme = (typeof ButtonThemes)[number]

const ButtonDisabledThemes = ['default', 'informational', 'black'] as const
export type ButtonDisabledTheme = (typeof ButtonDisabledThemes)[number]

export interface IButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  disabledTheme?: ButtonDisabledTheme
  loading?: boolean
  size?: ButtonSize
  theme?: ButtonTheme
  children: ReactNode
}

const Button = ({
  className = '',
  type = 'button',
  theme = 'blue',
  size = 'md',
  disabled = false,
  disabledTheme = 'default',
  loading,
  onClick,
  children,
  ...rest
}: IButtonProps) => {
  if (theme === 'clean' || theme === 'clean-blue') {
    const isDisabled = disabled || loading
    return (
      <button
        {...rest}
        className={classNames(
          cleanThemeClasses({ theme, disabled: isDisabled, size }),
          {
            'disabled:animate-pulse': loading
          },
          className
        )}
        type={type}
        onClick={onClick}
        disabled={isDisabled}
      >
        {children}
      </button>
    )
  }

  return (
    <button
      className={classNames(
        buttonThemeClasses({ theme, size, disabled: disabledTheme }),
        className
      )}
      type={type}
      onClick={onClick}
      disabled={disabled || loading}
      {...rest}
    >
      {loading && <Spinner />}
      <span
        className={classNames('flex items-center justify-center w-full', {
          invisible: loading
        })}
      >
        {children}
      </span>
    </button>
  )
}

interface IScrollToButton {
  className?: string
  theme?: ButtonTheme
  size?: ButtonSize
  offset?: number
  targetId: string
  onClick?: () => void
  children: ReactNode
}

export const ScrollToButton = ({
  className = '',
  theme = 'clean',
  size = 'lg',
  offset = 0,
  targetId,
  onClick,
  children
}: IScrollToButton) => {
  const handleScrollTo = () => {
    scrollIntoViewWithOffset(targetId, offset)
    onClick && onClick()
  }

  return (
    <Button
      theme={theme}
      size={size}
      className={className}
      onClick={handleScrollTo}
    >
      {children}
    </Button>
  )
}

export interface IButtonLink extends LinkProps {
  className?: string
  ariaLabel?: string
  disabledTheme?: ButtonDisabledTheme
  disabled?: boolean
  style?: React.CSSProperties
  size?: ButtonSize
  rel?: string
  theme?: ButtonTheme
  target?: HTMLAttributeAnchorTarget
  newTab?: boolean
  children: ReactNode
}

export const ButtonLink = ({
  ariaLabel,
  children,
  style,
  className,
  disabledTheme = 'default',
  disabled,
  newTab = false,
  size,
  href,
  target,
  rel,
  theme = 'blue',
  ...rest
}: IButtonLink) => {
  const _href = disabled ? '' : href.toString().trim()

  // lets check to see if this is an external url
  let isRelative = true
  if (typeof window !== 'undefined') {
    isRelative =
      new URL(document.baseURI).origin ===
      new URL(_href, document.baseURI).origin
  }

  // if disabled, just return a button with no
  // click action associated with it
  if (disabled) {
    return (
      <Button
        style={style}
        aria-label={ariaLabel}
        size={size}
        className={classNames(className, 'flex justify-center items-center')}
        disabled={true}
        theme={theme}
        disabledTheme={disabledTheme}
      >
        {children}
      </Button>
    )
  }

  const newTabSettings = newTab
    ? {
        target: '_blank',
        rel: 'noopener noreferrer'
      }
    : {}

  const isCleanTheme = theme === 'clean' || theme === 'clean-blue'
  const linkScaleOnHover = !isCleanTheme
  const themeClasses = isCleanTheme
    ? cleanThemeClasses({ theme, size })
    : buttonThemeClasses({ theme, size, linkScaleOnHover })

  if (isRelative) {
    return (
      <Link
        aria-label={ariaLabel}
        href={_href}
        target={target}
        rel={rel}
        {...newTabSettings}
        className={classNames(
          themeClasses,
          'flex items-center justify-center',
          className
        )}
        style={{ ...style }}
        {...rest}
      >
        {children}
      </Link>
    )
  } else {
    return (
      <a
        href={_href}
        target={target}
        rel={rel}
        aria-label={ariaLabel}
        {...newTabSettings}
        className={classNames(
          themeClasses,
          'flex items-center justify-center',
          className
        )}
        style={{ ...style }}
      >
        {children}
      </a>
    )
  }
}
const cleanThemeClasses = tv({
  variants: {
    theme: {
      clean: '',
      'clean-blue':
        'font-bold uppercase text-blue--light hover:text-blue transition-colors'
    },
    size: {
      xs: 'text-xs leading-none',
      sm: 'text-sm leading-none',
      md: 'text-sm leading-none',
      lg: 'text-base leading-none',
      auto: ''
    },
    disabled: {
      true: 'disabled:text-gray-500'
    }
  },
  defaultVariants: {
    theme: 'clean',
    size: 'md'
  }
})

const buttonThemeClasses = tv({
  variants: {
    theme: {
      blue: 'text-white bg-blue',
      white: 'text-gray-900 bg-white',
      'gray-blur': 'text-white bg-gray-700/30 backdrop-blur-sm',
      gray: 'text-white bg-gray-700',
      'gray-transparent': 'text-white bg-gray-700/70',
      black: 'text-white bg-gray-900',
      green: 'text-white bg-rarity-uncommon',
      rainbow: 'text-white bg-rainbow--linear',
      secondary: 'text-white bg-white/15',
      destructive: 'text-white bg-error-opaque border-1 border-error',
      'green-cta': 'text-white bg-rarity-uncommon',
      solana: 'text-white bg-solana--linear',
      blocked:
        'text-gray-400 bg-transparent bg-none shadow-buttonInset--transparent'
    },
    linkScaleOnHover: {
      true: 'hover:scale-105'
    },
    size: {
      xs: 'h-4 rounded-[12px] text-xs px-[12px]',
      sm: 'h-5 rounded-[16px] text-sm px-[12px]',
      md: 'h-6 rounded-[18px] text-sm px-2',
      lg: 'h-7 rounded-[20px] text-base px-3',
      auto: ''
    },
    disabled: {
      default:
        'disabled:text-gray-400 disabled:bg-transparent disabled:bg-none disabled:shadow-buttonInset--transparent disabled:border-0',
      informational:
        'disabled:bg-transparent disabled:shadow-buttonInset--transparent disabled:text-white',
      black:
        'disabled:bg-transparent disabled:shadow-buttonInset disabled:text-black'
    }
  },
  compoundVariants: [
    {
      class:
        'transition-transform duration-button transform-gpu focus:ring-blue/[.75] focus-visible:ring-2 font-primary font-bold uppercase whitespace-nowrap enabled:hover:scale-105 disabled:cursor-not-allowed'
    }
  ],
  defaultVariants: {
    theme: 'blue',
    size: 'md',
    disabled: 'default'
  }
})

export default Button
