import NotifyMeAction from '../Actions/NotifyMeAction'
import Button, { ButtonDisabledTheme, ButtonLink, ButtonTheme } from '../Button'
import { DROP_THEME } from '@/constants/drops'
import { DropState } from '@/enum'
import { initialPackSaleEndedWithinSevenDays } from '@/util/timeHelpers'
import { useDropContext } from '@/features/drop/DropContextProvider'
import { useRouter } from 'next/router'
import NiceModal from '@ebay/nice-modal-react'
import { MODAL_ID } from '@/constants/modalId'
import { sendGTMEvent } from '@next/third-parties/google'
import { DropQueueButton } from '@/features/drop/overview/DropQueueButton'
import CircleIconLink from '../CircleIconLink'
import SocialShareMenu from '../SocialShareMenu'

type Props = {
  isCondensed?: boolean
  isDetailPage?: boolean
}
const DropButtons = ({ isCondensed = false, isDetailPage = false }: Props) => {
  const {
    drop,
    state,
    isLaunched,
    isPostSale,
    isWithin8HoursBeforeQueue,
    isQueueOpen,
    time_launch
  } = useDropContext()

  const isLightTheme = drop.theme === DROP_THEME.LIGHT
  const bgAndTextClasses = isLightTheme ? 'bg-gray-700/70 text-white' : ''

  const contextualMenuConfig = {
    className: bgAndTextClasses,
    itemClassName: isLightTheme ? 'border-white' : '',
    buttonClassName: isLightTheme ? 'hover:text-gray-900' : ''
  }

  const disabledTheme: ButtonDisabledTheme = isLightTheme
    ? 'black'
    : 'informational'
  const buttonTheme: ButtonTheme = isLightTheme ? 'black' : 'white'
  const secondaryButtonTheme: ButtonTheme = isLightTheme
    ? 'gray-transparent'
    : 'secondary'

  const shouldShowSocialLinks = !isLaunched

  const { push } = useRouter()

  if (isDetailPage) {
    if (state == 'lite_ultra') {
      return null
    }

    const handleHowItWorks = (source: string) => {
      NiceModal.show(MODAL_ID.howItWorks, { catalogItem: drop })

      sendGTMEvent({
        event: 'view_how_it_works',
        hiw_source: source
      })
    }

    const renderDynamicButtons = () => {
      if (isPostSale)
        return (
          <Button
            disabledTheme={disabledTheme}
            theme={buttonTheme}
            size="lg"
            onClick={() => {
              if (drop.drop_marketplace_disabled_primary === true) {
                NiceModal.show(MODAL_ID.openingSoon, {
                  opensAt: time_launch
                })
              } else {
                push(`${drop.url}/shop`)
              }
            }}
          >
            Shop Collection
          </Button>
        )

      return isQueueOpen || isWithin8HoursBeforeQueue ? (
        <DropQueueButton
          drop={drop}
          theme={buttonTheme}
          disabledTheme={disabledTheme}
        />
      ) : (
        <NotifyMeAction dropId={drop.id} buttonTheme={buttonTheme} />
      )
    }

    return (
      <div className="relative flex flex-col items-center justify-center text-center mx-auto px-2 max-w-[832px]">
        <div className="flex flex-col md:flex-row items-center gap-2">
          {renderDynamicButtons()}
          <Button
            size="lg"
            theme={secondaryButtonTheme}
            onClick={() => handleHowItWorks('hiw_overview_hero')}
          >
            How It Works
          </Button>
          {shouldShowSocialLinks && (
            <div className="flex justify-center gap-2">
              <CircleIconLink
                name="discord"
                url={drop.discordUrl}
                className={bgAndTextClasses}
              />
              <SocialShareMenu
                circleIconButtonClassName={bgAndTextClasses}
                contextualMenuConfig={contextualMenuConfig}
              />
            </div>
          )}
        </div>
      </div>
    )
  } else {
    const className = isCondensed
      ? 'relative flex items-center justify-start text-center mx-auto max-w-[639px]'
      : 'relative flex items-center justify-center text-center mx-auto px-[2em] max-w-[832px]'

    const buttonSize = isCondensed ? 'md' : 'lg'

    if ([DropState.AnnounceLiteUltra].includes(state as DropState)) {
      return null
    }

    if (isPostSale && initialPackSaleEndedWithinSevenDays(time_launch)) {
      return (
        <div className={className}>
          <div className="flex max-sm:flex-col max-sm:items-center gap-2">
            <ButtonLink theme={buttonTheme} size={buttonSize} href={drop.url}>
              View Collection
            </ButtonLink>
            <ButtonLink
              size={buttonSize}
              theme={secondaryButtonTheme}
              href={`${drop.url}/shop`}
            >
              Shop Now
            </ButtonLink>
          </div>
        </div>
      )
    }

    if (isQueueOpen || isWithin8HoursBeforeQueue) {
      return (
        <div className={className}>
          <div className="flex max-sm:flex-col max-sm:items-center gap-2">
            {state !== DropState.SaleContinued ? (
              <>
                <DropQueueButton
                  drop={drop}
                  theme={buttonTheme}
                  disabledTheme={disabledTheme}
                  size={buttonSize}
                />
                <ButtonLink
                  size={buttonSize}
                  theme={secondaryButtonTheme}
                  href={drop.url}
                >
                  Learn More
                </ButtonLink>
              </>
            ) : (
              <>
                <Button
                  disabledTheme={disabledTheme}
                  theme={buttonTheme}
                  size={buttonSize}
                  onClick={() =>
                    (location.href = '/reserve-drop/?drop_id=' + drop.id)
                  }
                >
                  Buy Now
                </Button>
                <ButtonLink
                  size={buttonSize}
                  theme={secondaryButtonTheme}
                  href={drop.url}
                >
                  Learn More
                </ButtonLink>
              </>
            )}
          </div>
        </div>
      )
    }

    return (
      <div className={className}>
        <div className="flex max-sm:flex-col max-sm:items-center gap-2">
          <NotifyMeAction
            dropId={drop.id}
            buttonTheme={buttonTheme}
            size={buttonSize}
          />
          <ButtonLink size={buttonSize} theme={buttonTheme} href={drop.url}>
            Learn More
          </ButtonLink>
        </div>
      </div>
    )
  }
}

export default DropButtons
