import post from '@/api/core/http/post'
import { buildApiUrl } from '@/api/core/url'
import {
  PurchaseListingStartResponse,
  purchaseListingStartResponseSchema
} from './schema'
import { PurchaseListingInput } from '@/api/resources/user/listing/purchase/shared'
import { AuthMetaType } from '@/api/core/http/types'

export const path = `/user/listing/purchase/start`

export const purchaseListingStart = async (
  input: PurchaseListingInput,
  meta: AuthMetaType
): Promise<PurchaseListingStartResponse> =>
  post<PurchaseListingStartResponse>(
    buildApiUrl(path, input),
    { meta },
    purchaseListingStartResponseSchema
  )
