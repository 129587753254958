import { Icon, RarityBadge } from '../components'
import Link from 'next/link'
import { RARITY_TYPES } from '@/enum'

const FAQ_QUESTIONS = {
  drop101: {
    id: 'drop101',

    navLabel: 'Droppp 101',
    title: 'Droppp 101',
    items: {
      whatIsDroppp: {
        id: 'what-is-droppp',
        title: 'What is Droppp?',
        answer: (
          <>
            <p className="mb-2">
              Droppp is the premier platform for buying and collecting pop
              culture digital collectibles (NFTs) with super-easy and secure
              purchase methods.
            </p>
            <p>
              We collaborate with some of the most widely-regarded pop culture
              brands to bring fans unique digital collectibles — some of which
              can also be redeemed for super-rare physical collectibles!
            </p>
          </>
        )
      },
      whatAreDrops: {
        id: 'what-are-drops',
        title: 'What are drops?',
        answer: (
          <p>
            A drop is the initial sale for the purchase of digital packs. After
            purchase, packs will show up in your inventory where you can
            digitally open them. Once opened, digital collectibles will be
            deposited into your inventory from that pack. Opening a pack will
            instantly remove it from your inventory.
          </p>
        )
      },
      howDoIGetStartedCollecting: {
        id: 'how-do-i-get-started-collecting-here-on-droppp',
        title: 'How do I get started collecting here on Droppp?',
        answer: (
          <>
            <p>
              Follow our{' '}
              <Link
                href="/get-started"
                className="inline-link"
                target="_blank"
                rel="noreferrer"
              >
                Get Started
              </Link>{' '}
              guide! You'll be up and running in no time!
            </p>
          </>
        )
      },
      howDoIPurchase: {
        id: 'how-do-i-purchase-digital-collectibles-on-droppp',
        title: 'How do I purchase digital collectibles on Droppp?',
        answer: (
          <p>
            You can purchase packs by joining the queue when the drop starts.
            Packs may be purchased with{' '}
            <Link
              target="_blank"
              href="/faq?question=what-payments-are-accepted-to-purchase-items-on-droppp"
              className="inline-link"
              rel="noreferrer"
            >
              various payment methods
            </Link>
            . After the drop concludes, packs and digital collectibles will be
            available for purchase on Droppp Marketplace by other Droppp users.
          </p>
        )
      },
      whyDoesMyProfileEndInDp: {
        id: 'why-does-my-profile-end-in-dp',
        title: (
          <>
            Why does my profile end in&nbsp;<em>.dp</em>?
          </>
        ),
        answer: (
          <>
            <p>
              Your profile name is derived from your Droppp Address, which is an
              address on the WAX blockchain used to store and receive digital
              collectibles. All Droppp Addresses, whether standard or custom,
              end with <em>.dp</em>. For just $4.99, you can{' '}
              <Link
                href="/wallet"
                className="inline-link"
                target="_blank"
                rel="noreferrer"
              >
                upgrade
              </Link>{' '}
              to a custom <em>.dp</em> address, which will also become your new
              profile name.
            </p>
          </>
        )
      },
      whatPaymentsAreAccepted: {
        id: 'what-payments-are-accepted-to-purchase-items-on-droppp',
        title: 'What payments are accepted to purchase items on Droppp?',
        answer: (
          <p>
            For pack sales, custom addresses and redemptions, acceptable payment
            methods include Apple Pay, Google Pay, and credit cards (see{' '}
            <a
              href="https://stripe.com/docs/payments/cards/supported-card-brands"
              className="inline-link"
              target="_blank"
              rel="noreferrer"
            >
              supported credit cards
            </a>
            ).
            <>
              {' '}
              Droppp Marketplace transactions use{' '}
              <Link
                target="_blank"
                href="/faq/?question=what-is-usdc"
                className="inline-link"
                rel="noreferrer"
              >
                USDC
              </Link>{' '}
              .
            </>
          </p>
        )
      },
      whatDoesDropppExclusiveMean: {
        id: 'what-does-droppp-exclusive-mean',
        title: 'What does Droppp Exclusive mean?',
        answer: (
          <>
            <div>
              <span>
                Droppp Exclusive refers to Digital Pop! collections that you can
                only buy, sell, and trade on Droppp. Look for the{' '}
              </span>
              <Icon
                name="exclusiveBadgeTextWhiteSmall"
                className="inline-block mx-[6px]"
              />
              <span>
                {' '}
                badge to know which collections are Droppp Exclusives.
              </span>
            </div>
          </>
        )
      },
      canITransferDropppExclusivesToAnotherAddress: {
        id: 'can-i-transfer-droppp-exclusives-to-another-address',
        title: 'Can I transfer Droppp Exclusives to another address?',
        answer: (
          <p>
            You may transfer Droppp Exclusive items to another Droppp address
            (ending in .dp), but may not transfer to other WAX blockchain
            addresses.
          </p>
        )
      },
      willAllDropsMovingForwardBeDropppExclusiveCollections: {
        id: 'will-all-drops-moving-forward-be-droppp-exclusive-collections',
        title: 'Will all drops moving forward be Droppp Exclusive collections?',

        answer: (
          <>
            <div>
              <span>
                No, only certain collections are Droppp Exclusive. All Droppp
                Exclusive collections and items will be marked by the{' '}
              </span>
              <Icon
                name="exclusiveBadgeTextWhiteSmall"
                className="inline-block mx-[6px]"
              />
              <span>
                {' '}
                badge on the drop landing page and throughout the marketplace.
              </span>
            </div>
          </>
        )
      }
    }
  },
  initialpacksale: {
    id: 'initialpacksale',
    navLabel: 'Drop',
    title: 'Drop',
    items: {
      canIUseACreditCard: {
        id: 'can-i-use-a-credit-card-on-droppp-to-buy-items-in-the-initial-pack-sale',
        title: 'Can I use a credit card to buy items in the drop?',
        answer: (
          <p>
            Droppp accepts most major credit cards along with Apple Pay and
            Google Pay for all drops.
          </p>
        )
      },
      howDoesJoiningAQueueWork: {
        id: 'how-does-joining-a-queue-for-a-new-drop-work',
        title: 'How does joining a queue for a new drop work?',
        answer: (
          <p>
            A drop's queue opens roughly 10 minutes before the scheduled drop
            time. We use the Queue-It system for our queues. Joining the queue
            allows you to reserve a random spot before the drop opens. When the
            drop goes live, the Queue-It system randomizes everyone that had
            joined the queue. Joining the queue after the drop has gone live,
            will place you at the back of the queue. It’s important to note that
            checking out and rejoining the queue will place you at the back of
            the queue.
          </p>
        )
      }
    }
  },
  wallet: {
    id: 'wallet',
    navLabel: 'Wallet',
    title: 'Wallet',
    items: {
      whatIsDropppWallet: {
        id: 'what-is-droppp-wallet',
        title: 'What is Droppp Wallet?',
        answer: (
          <>
            <p className="mb-2">
              Droppp Wallet is your personal digital wallet for managing funds
              on Droppp Marketplace. Think of it as the digital equivalent of
              your physical wallet, but instead of paper money, it holds{' '}
              <Link
                href="/faq/?question=what-is-usdc"
                rel="noreferrer"
                target="_blank"
                className="inline-link"
              >
                USDC
              </Link>
              , a digital currency.
            </p>
            <p>
              You can add funds to your wallet by transferring USDC from
              external crypto like{' '}
              <Link
                href="https://coinbase.com"
                className="inline-link"
                rel="noreferrer"
                target="_blank"
              >
                Coinbase
              </Link>{' '}
              or{' '}
              <Link
                href="https://robinhood.com/"
                className="inline-link"
                rel="noreferrer"
                target="_blank"
              >
                Robinhood
              </Link>
              . When you sell items on Droppp, your are deposited right into
              your wallet. Curious about the technical details? Head over to{' '}
              <Link
                href="/faq/?question=how-does-droppp-wallet-work-under-the-hood"
                className="inline-link"
                rel="noreferrer"
                target="_blank"
              >
                How does Droppp Wallet work under the hood?
              </Link>
              .
            </p>
          </>
        )
      },
      howDoesDropppWalletWorkUnderTheHood: {
        id: 'how-does-droppp-wallet-work-under-the-hood',
        title: 'How Does Droppp Wallet Work Under The Hood?',
        answer: (
          <>
            <p className="mb-2">
              Droppp Wallet is a self-custody wallet on the{' '}
              <Link
                href="http://solana.com/"
                className="inline-link"
                rel="noreferrer"
                target="_blank"
              >
                Solana
              </Link>{' '}
              blockchain, which means you have full control . Neither Droppp nor
              our wallet security provider,{' '}
              <Link
                href="http://turnkey.com/"
                className="inline-link"
                rel="noreferrer"
                target="_blank"
              >
                Turnkey
              </Link>
              , can access or manage your wallet — only you can.
            </p>
            <p className="mb-2">
              When you set up your wallet, cryptographic keys were created and
              secured by a passkey stored exclusively on your device. This
              passkey allows you to quickly and securely authorize transactions,
              typically with just a fingerprint or face scan.
            </p>
            <p className="mb-2">
              Sometimes, you may need to reconnect your wallet if your session
              times out or if youʼre accessing Droppp on a different device. If
              your passkey syncs automatically across devices through a password
              manager or operating system, you can seamlessly log in using the
              same passkey you initially set up. If your passkey doesnʼt sync,
              youʼll be prompted to add a new passkey to your account to
              reconnect (in addition to the existing passkeys you already set
              up). For detailed help, see{' '}
              <Link
                href="/faq/?question=help-i-cant-connect-my-droppp-wallet"
                className="inline-link"
                rel="noreferrer"
                target="_blank"
              >
                Help! I canʼt connect my Droppp Wallet
              </Link>
              .
            </p>
            <p className="mb-2">
              To boil it all down, Droppp Wallet is a secure Solana wallet fully
              under your control. Every transaction you authorize with your
              passkey is cryptographically signed by you and sent directly to
              the Solana blockchain for execution. Droppp and Turnkey do not
              have access to your wallet — only you do.
            </p>
            <p className="mb-2">
              As a reminder, Droppp Wallet does not affect the underlying
              blockchain WAX that holds our digital collectibles; only the
              payment method on Droppp Marketplace.
            </p>
          </>
        )
      },
      howDoIAddFundsToMyDropppWallet: {
        id: 'how-do-i-add-funds-to-my-droppp-wallet',
        title: 'How do I add funds to my Droppp Wallet?',
        answer: (
          <p>
            Adding funds is straightforward! Start by purchasing USDC on an
            exchange like{' '}
            <Link
              href="https://coinbase.com"
              className="inline-link"
              rel="noreferrer"
              target="_blank"
            >
              Coinbase
            </Link>{' '}
            or{' '}
            <Link
              href="https://robinhood.com/"
              className="inline-link"
              rel="noreferrer"
              target="_blank"
            >
              Robinhood
            </Link>{' '}
            using your credit card, debit card, or bank account. Once the funds
            are in your exchange account, come back to Droppp, click{' '}
            <strong className="text-white">
              <em>Add Funds</em>
            </strong>
            , and follow the steps to transfer USDC into your{' '}
            <Link
              href="/wallet"
              className="inline-link"
              rel="noreferrer"
              target="_blank"
            >
              Wallet
            </Link>
            .
          </p>
        )
      },
      howDoIWithdrawFundsFromMyDropppWallet: {
        id: 'how-do-i-withdraw-funds-from-my-droppp-wallet',
        title: 'How do I withdraw funds from my Droppp Wallet?',
        answer: (
          <>
            <p className="mb-2">
              When itʼs time to withdraw your funds, simply click{' '}
              <strong className="text-white">
                <em>Withdraw</em>
              </strong>{' '}
              in your{' '}
              <Link
                href="/wallet"
                className="inline-link"
                rel="noreferrer"
                target="_blank"
              >
                Wallet
              </Link>{' '}
              and follow the instructions to withdraw back to an exchange.
            </p>
            <p>
              Pro tip: If you withdraw funds directly through Droppp, weʼll
              cover the fees charged by the Solana blockchain, so you wonʼt need
              to keep SOL in your wallet to pay for gas fees. Pretty convenient!
            </p>
          </>
        )
      },
      whyIsMyDropppWalletDisconnected: {
        id: 'why-is-my-droppp-wallet-disconnected',
        title: 'Why Is My Droppp Wallet Disconnected?',
        answer: (
          <>
            <p className="mb-2">
              If your wallet becomes disconnected, itʼs probably because your
              session timed out or youʼre accessing Droppp on a different
              device. Not a problem! Just click{' '}
              <strong className="text-white">
                <em>Reconnect Wallet</em>
              </strong>{' '}
              anywhere on Droppp, and reauthorize using your passkey.
            </p>
            <p>
              Having trouble reconnecting? Check out the next section:{' '}
              <Link
                href="/faq/?question=help-i-cant-connect-my-droppp-wallet"
                className="inline-link"
                rel="noreferrer"
                target="_blank"
              >
                Help! I canʼt connect my Droppp Wallet
              </Link>
              .
            </p>
          </>
        )
      },
      helpICantConnectMyDropppWallet: {
        id: 'help-i-cant-connect-my-droppp-wallet',
        title: 'Help! I canʼt connect my Droppp Wallet',
        answer: (
          <>
            <p className="mb-2">
              If youʼre switching devices and your passkey doesnʼt sync, you
              might find it tricky to reconnect. For example, if you set up your
              passkey on a Windows computer and now want to use it on an iPhone,
              it might not carry over automatically.
            </p>
            <p className="mb-2">
              No worries! You can add a new passkey. Just click{' '}
              <strong className="text-white">
                <em>Reconnect Wallet</em>
              </strong>{' '}
              and follow the link for{' '}
              <strong className="text-white">
                <em>My Passkey Isn't Working</em>
              </strong>{' '}
              . Turnkey, our wallet security provider, will email you a secure
              link to create a new passkey.
            </p>
            <p>
              If youʼve lost both your passkey and access to your recovery
              email, you can still access your funds using your seed phrase (see{' '}
              <Link
                href="/faq/?question=can-i-import-my-droppp-wallet-into-an-external-solana-wallet-app"
                className="inline-link"
                rel="noreferrer"
                target="_blank"
              >
                Can I import my Droppp Wallet into an external Solana wallet
                app?
              </Link>
              . Reach out to{' '}
              <Link
                href="/support"
                className="inline-link"
                rel="noreferrer"
                target="_blank"
              >
                Droppp Support
              </Link>{' '}
              for assistance.
            </p>
          </>
        )
      },
      whyDoINeedToMigrateMyDropppBalanceToDropppWallet: {
        id: 'why-do-i-need-to-migrate-my-droppp-balance-to-droppp-wallet',
        title: 'Why do I need to migrate my Droppp Balance to Droppp Wallet?',
        answer: (
          <>
            <p className="mb-2">
              Droppp Balance was the name of our previous wallet technology,
              which was issued and managed by our third-party provider,{' '}
              <Link
                href="https://www.circle.com/"
                className="inline-link"
                rel="noreferrer"
                target="_blank"
              >
                Circle
              </Link>
              . Weʼve since transitioned to Droppp Wallet, a system where you
              have control over the keys that secure your wallet.
            </p>
            <p className="mb-2">
              As part of this shift, our marketplace payment infrastructure has
              been reengineered to only work with Droppp Wallet. This means
              youʼll need to migrate your Droppp Balance to continue buying and
              selling on the marketplace. Without migrating, you wonʼt be able
              to list or purchase items, and any of your existing listings will
              be deactivated.
            </p>
            <p className="mb-2">
              The migration process is simple — just start it from the{' '}
              <Link
                href="/wallet"
                className="inline-link"
                rel="noreferrer"
                target="_blank"
              >
                Wallet
              </Link>{' '}
              page and follow the step-by-step guide — it only takes a few
              minutes! Once youʼve migrated, youʼll be able to reactivate your
              listings in your inventory.
            </p>
            <p>
              This change doesnʼt affect primary sales, which still support
              traditional payment methods and is unaffected by the transition to
              Droppp Wallet.
            </p>
          </>
        )
      },
      whatArePasskeysAndSeedPhrases: {
        id: 'what-are-passkeys-and-seed-phrases',
        title: 'What are Passkeys and Seed Phrases?',
        answer: (
          <>
            <p className="mb-2">
              We know this terminology can be a bit confusing, but donʼt worry —
              you only need to understand the basics to use Droppp Marketplace.
              If youʼre curious to dive deeper, hereʼs what these security terms
              mean:
            </p>
            <ul className="list-disc pl-4 mb-2">
              <li className="mb-2">
                <p>
                  <strong className="text-white font-extrabold">
                    Seed Phrase:
                  </strong>
                  <br />A seed phrase is the ultimate key to your Solana
                  blockchain wallet. On Droppp, you donʼt need to interact
                  directly with your seed phrase because itʼs secured by a
                  passkey. However, if youʼd like access to it — for example, to
                  use your wallet with an external app — you can view your seed
                  phrase in Droppp Walletʼs management settings (see:{' '}
                  <Link
                    href="/faq/?question=can-i-import-my-droppp-wallet-into-an-external-solana-wallet-app"
                    className="inline-link"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Can I import my Droppp Wallet into an external Solana wallet
                    app?
                  </Link>
                  .
                </p>
              </li>
              <li>
                <p>
                  <strong className="text-white font-extrabold">
                    Passkey:
                  </strong>
                  <br />A{' '}
                  <Link
                    href="https://www.passkeys.io/"
                    className="inline-link"
                    rel="noreferrer"
                    target="_blank"
                  >
                    passkey
                  </Link>{' '}
                  is a modern authentication standard developed by the FIDO
                  Alliance. Droppp Wallet uses a third-party wallet security
                  provider,{' '}
                  <Link
                    href="http://turnkey.com/"
                    className="inline-link"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Turnkey
                  </Link>
                  , to secure your seed phrase with a passkey. This eliminates
                  the need to remember your seed phrase when signing
                  transactions. Instead, your browser will prompt you for your
                  passkey, which is typically verified using a face or
                  fingerprint scan.
                </p>
              </li>
            </ul>
          </>
        )
      },
      canIImportMyDropppWalletIntoAnExternalSolanaWalletApp: {
        id: 'can-i-import-my-droppp-wallet-into-an-external-solana-wallet-app',
        title:
          'Can I import my Droppp Wallet into an external Solana wallet app?',
        answer: (
          <>
            <p className="mb-2">
              Absolutely. With your Droppp Wallet connected, go to{' '}
              <strong className="text-white">
                <em>Manage → Seed Phrase</em>
              </strong>{' '}
              to reveal your walletʼs seed phrase. You can use this to import
              your wallet into an app like Phantom.
            </p>
            <p className="mb-2">
              When you do this, make sure to read the precautions carefully.
              Your seed phrase is like the master key to your wallet, so itʼs
              important to keep it private and secure.
            </p>
            <p>Never share it with anyone, not even Droppp staff.</p>
          </>
        )
      },
      helpIGaveOutMySeedPhraseToSomeoneElseWhatShouldIDo: {
        id: 'help-i-gave-out-my-seed-phrase-to-someone-else-what-should-i-do',
        title:
          'Help! I gave out my seed phrase to someone else. What should I do?',
        answer: (
          <>
            <p className="mb-2">
              Your seed phrase is the key to your wallet, and itʼs critical
              never to share it with anyone. However, if someone gained access
              to your seed phrase and you still have access to your funds, act
              quickly. First, transfer your funds immediately back to the
              exchange you originally used to send funds to Droppp Wallet. This
              ensures your funds are secure.
            </p>
            <p className="mb-2">
              Once your funds are safe, contact{' '}
              <Link
                href="/support"
                className="inline-link"
                rel="noreferrer"
                target="_blank"
              >
                Droppp Support
              </Link>{' '}
              as soon as possible. We can help by removing the compromised
              wallet from your Droppp account and resetting it so you can set up
              a new wallet.
            </p>
            <p>
              Unfortunately, if your funds have already been accessed and
              removed by someone else, there is no way for us to help you
              recover them.
            </p>
          </>
        )
      },
      whatIsUsdc: {
        id: 'what-is-usdc',
        title: 'What is USDC?',
        answer: (
          <>
            <p>
              <Link
                href="https://www.usdc.com"
                className="inline-link"
                rel="noreferrer"
                target="_blank"
              >
                USDC
              </Link>{' '}
              is a stablecoin that is meant to work like a substitute for a U.S.
              dollar. Currently, Droppp only supports USDC for funding your
              Droppp Wallet and making purchases on the marketplace.
            </p>
          </>
        )
      },
      checkAvailableFunds: {
        id: 'how-do-i-check-my-available-usdc-balance',
        title: 'How do I check my available USDC balance?',
        answer: (
          <>
            <p>
              The USDC balance of your Droppp Wallet is always accessible from
              the top navigation bar and on the{' '}
              <Link
                href="/wallet"
                className="inline-link"
                rel="noreferrer"
                target="_blank"
              >
                Wallet
              </Link>{' '}
              page.
            </p>
          </>
        )
      },
      canIStillSendUSDCOnTheEthereumOrPolygonNetwork: {
        id: 'can-i-still-send-usdc-on-the-ethereum-or-polygon-network',
        title: 'Can I still send USDC on the Ethereum or Polygon network?',
        answer: (
          <p>
            No, Droppp Wallet is based on the Solana blockchain, so you must
            send USDC over the Solana network. Sending USDC via Ethereum,
            Polygon, or any other network will result in a loss of funds, which
            cannot be recovered by Droppp.
          </p>
        )
      },
      canIUseMyUSDCBalanceToPurchasePacksDuringADrop: {
        id: 'can-i-use-my-usdc-balance-to-purchase-packs-during-a-drop',
        title: 'Can I use my USDC balance to purchase packs during a drop?',
        answer: (
          <p>Not at the moment, but weʼll let you know if that changes!</p>
        )
      },
      canIUseMyExistingWAXWalletOnADroppp: {
        id: 'can-i-use-my-existing-wax-wallet-on-a-droppp',
        title: 'Can I use my existing WAX wallet on Droppp?',
        answer: (
          <p>
            No, Droppp requires you to create a free or custom account
            specifically for our platform.
          </p>
        )
      }
    }
  },
  marketplace: {
    id: 'marketplace',
    navLabel: 'Marketplace',

    title: (
      <>
        Droppp <span className="uppercase inline-block">MarketPlace</span>
      </>
    ),
    items: {
      whatisTheMarketplace: {
        id: 'what-is-the-droppp-marketplace',
        title: 'What is Droppp Marketplace?',

        answer: (
          <>
            <p className="mb-2">
              Droppp Marketplace is Droppp’s official secondary marketplace,
              where you can buy, sell, and collect digital collectibles like
              Funko Digital Pop!™ and Droppp Monsters.
            </p>
          </>
        )
      },
      canIListAndSellAnyDigitalCollectibles: {
        id: 'can-i-list-and-sell-any-digital-collectibles',
        title: 'Can I list and sell any digital collectibles?',

        answer: (
          <p>
            Droppp Marketplace is currently limited to only Funko Digital Pop!™
            and other digital collectibles that were initially sold on Droppp,
            such as{' '}
            <Link
              href="/drop/93/droppp-monsters-series-1/"
              className="inline-link"
              rel="noreferrer"
              target="_blank"
            >
              Droppp Monsters
            </Link>
            . All other items are currently unsupported. If you transfer any
            unsupported items to Droppp, you will need to{' '}
            <Link
              href="/support"
              className="inline-link"
              rel="noreferrer"
              target="_blank"
            >
              contact support
            </Link>{' '}
            in order to transfer your items back to an external wallet.
          </p>
        )
      },
      canIListAndSellADigitalCollectibleDuringAnIntialPackSale: {
        id: 'can-i-list-and-sell-a-digital-collectible-during-an-initial-pack-sale',
        title: 'Can I list and sell a digital collectible during a drop?',
        answer: (
          <p>
            Shortly after a drop starts, you can list its corresponding digital
            collectibles on Droppp Marketplace.
          </p>
        )
      },
      howDoIListAndSellADigitaCollectible: {
        id: 'how-do-i-list-and-sell-a-digital-collectible-on-droppp-marketplace',
        title:
          'How do I list and sell a digital collectible on Droppp Marketplace?',
        answer: (
          <>
            <p className="mb-2">
              Go to your{' '}
              <Link
                href="/inventory"
                className="inline-link"
                rel="noreferrer"
                target="_blank"
              >
                Inventory
              </Link>{' '}
              to begin listing on the marketplace. From there, click any item to
              list it from the detail pane. Review your list price and fee
              breakdowns before confirming it for sale.
            </p>
            <p>
              If you wish to modify or cancel your listing, select the item from
              the{' '}
              <Link
                href="/inventory/?show_only_listings=true"
                className="inline-link"
                rel="noreferrer"
                target="_blank"
              >
                Listings
              </Link>{' '}
              tab in your inventory and adjust accordingly.
            </p>
          </>
        )
      },
      howDoIBuyADigitalCollectibleOnDropppMarketplace: {
        id: 'how-do-i-buy-a-digital-collectible-on-droppp-marketplace',
        title: 'How do I buy a digital collectible on Droppp Marketplace?',
        answer: (
          <p>
            You must be signed in and have enough funds in order buy a digital
            collectible. Click BUY LOWEST LISTING on your favorite collectible
            and it will be reserved for you for 60 seconds. After the
            reservation expires, the listing will return to the open market.
          </p>
        )
      },
      whatIsAListingReservation: {
        id: 'what-is-a-listing-reservation',
        title: 'What is a listing reservation?',
        answer: (
          <p>
            You can reserve a listing for 60 seconds. This is beneficial so
            other users can’t buy a listing out from under you. Once the
            reservation time is up, the listing will be available to other users
            to reserve and buy.
          </p>
        )
      },
      canIReserveMultipleListings: {
        id: 'can-i-reserve-multiple-listings',
        title: 'Can I reserve multiple listings?',
        answer: (
          <p>
            No, only one listing can be reserved at a time. If you try to
            reserve another listing while one is active, the first reservation
            will be canceled.
          </p>
        )
      },
      listingsManagementOnMarketplace: {
        id: 'how-do-i-view-and-manage-my-listings-on-droppp-marketplace',
        title: 'How do I view and manage my listings on Droppp Marketplace?',
        answer: (
          <p>
            Go to the{' '}
            <Link
              href="/inventory/?show_only_listings=true"
              className="inline-link"
              rel="noreferrer"
              target="_blank"
            >
              Listings
            </Link>{' '}
            tab in your Inventory from the main navigation menu. From there,
            you’ll be able to view, modify, or cancel your listings.
          </p>
        )
      },
      otherPaymentMethods: {
        id: 'does-droppp-support-other-payment-methods',
        title: 'Does Droppp Marketplace support other payment methods?',
        answer: <p>Currently, we only support USDC.</p>
      },
      marketplaceFees: {
        id: 'what-are-the-droppp-marketplace-fees',
        title: 'What are the Droppp Marketplace fees?',
        answer: (
          <>
            <p className="mb-2">
              When your listing sells, a few fees will be collected. These fees
              are:
            </p>
            <ul className="list-disc pl-4 mb-2">
              <li className="mb-2">
                The <b className="text-white">Collection Fee</b> is a royalty
                paid to the original collection for every secondary sale of the
                digital item.
              </li>
              <li className="mb-2">
                The <b className="text-white">Blockchain Fee</b> is payment to
                execute permanent and secure transactions on the blockchain.
                This is FREE on Droppp Marketplace.
              </li>
              <li>
                The <b className="text-white">Marketplace Fee</b> is a selling
                fee that allows us to operate and manage the Droppp Marketplace.
              </li>
            </ul>
            <p className="mb-2">
              These fees will be shown in detail when you list an item.
            </p>
            <p>
              When you withdraw USDC from your Droppp Wallet to an external
              wallet, you are subject to a{' '}
              <b className="text-white">Withdrawal Fee</b>. This fee fluctuates
              based on applicable current network fees and will be displayed at
              the time of the withdrawal.{' '}
            </p>
          </>
        )
      },
      whyIdentityVerification: {
        id: 'why-do-i-have-to-verify-my-identity',
        title: 'Why do I have to verify my identity?',
        answer: (
          <>
            <p className="mb-2">
              In order to comply with U.S. regulations, you must verify your
              identity in order to participate in Droppp Marketplace. If you do
              not verify your identity, you will not be able to access and use
              Droppp Marketplace.
            </p>
            <p>
              Initiating the verification process only takes about 10 minutes
              and will require a valid photo I.D. (region restrictions apply).
            </p>
          </>
        )
      },
      regionRestrictions: {
        id: 'what-are-the-droppp-marketplace-region-restrictions',
        title: 'What are the Droppp Marketplace region restrictions?',
        answer: (
          <>
            <p className="mb-2">
              The following countries are fully restricted and cannot
              participate in Droppp Marketplace: Central African Republic (the),
              Congo (the Democratic Republic of the), Cuba, Guinea-Bissau, Iran
              (Islamic Republic of), Iraq, Korea (the Democratic People's
              Republic of), Libya, Mali, Myanmar, Russia, Somalia, South Sudan,
              Sudan (the), Syrian Arab Republic, Ukraine, Venezuela (Bolivarian
              Republic of), and Yemen.
            </p>
            <p>An announcement will be made when new regions are supported.</p>
          </>
        )
      },
      howDoIVerifyMyIdentity: {
        id: 'how-do-i-verify-my-identity',
        title: 'How do I verify my identity?',
        answer: (
          <p>
            To verify your identity, go to your Wallet page and click on Verify
            Identity. You will confirm your location and be redirected to our
            third-party identity verification provider, Persona, to complete
            your verification process. Initiating the verification process only
            takes about 10 minutes and will require a valid photo I.D.
          </p>
        )
      },
      identityVerificationStatus: {
        id: 'i-am-waiting-for-my-id-verification-to-be-approved-how-do-i-check-on-the-status',
        title:
          'I am waiting for my I.D. verification to be approved. How do I checkon the status?',
        answer: (
          <p>
            You will receive an email with your verification results once your
            identity has been reviewed.
          </p>
        )
      },
      whyDoINeedToApproveTransactions: {
        id: 'why-do-i-need-to-approve-transactions',
        title: 'Why do I need to approve transactions?',
        answer: (
          <>
            <p className="mb-2">
              Droppp Marketplace uses Droppp Wallet for purchases, and the funds
              within your wallet are fully owned and managed by you. Droppp
              cannot move your funds, so you must authorize each transaction
              using the passkey you set up when creating your Droppp Wallet.
            </p>
            <p className="mb-2">
              For high-volume purchasing, like filling in a collection in the
              Collection Tracker, weʼve made the process even easier. You can
              opt in to an auto-approval period, during which your browser will
              automatically sign transactions on your behalf without requiring
              you to scan your passkey each time.
            </p>
            <p>
              For more details about Droppp Wallet, see{' '}
              <Link
                href="/faq/?question=what-is-droppp-wallet"
                className="inline-link"
                rel="noreferrer"
                target="_blank"
              >
                What is Droppp Wallet?
              </Link>
              .
            </p>
          </>
        )
      },
      whyAreMyListingsDeactivated: {
        id: 'why-are-my-listings-deactivated',
        title: 'Why are my listings deactivated?',
        answer: (
          <>
            <p className="mb-2">
              Your listings were deactivated on December 12, 2024, when we
              transitioned from Droppp Balance to Droppp Wallet. As part of this
              transition, all listings were temporarily deactivated until users
              migrated from the legacy Droppp Balance system to the new Droppp
              Wallet.
            </p>
            <p>
              To reactivate your listings, complete the migration process. For
              more details, see{' '}
              <Link
                href="/faq/?question=why-do-i-need-to-migrate-my-droppp-balance-to-droppp-wallet"
                className="inline-link"
                rel="noreferrer"
                target="_blank"
              >
                Why do I need to migrate my Droppp Balance to Droppp Wallet?
              </Link>
              .
            </p>
          </>
        )
      },
      whatDoesItMeanWhenAPurchaseOrSaleFails: {
        id: 'what-does-it-mean-when-a-purchase-or-sale-fails',
        title: 'What does it mean when a purchase or sale fails?',
        answer: (
          <p>
            When a purchase or sale fails, it means the transaction could not be
            completed on the Solana blockchain. This can happen for a variety of
            reasons, though it is relatively uncommon. If this occurs, the item
            being purchased is returned to the seller, and both the buyer and
            seller are notified. The reason for the failure can be viewed in the
            activity section of their accounts.
          </p>
        )
      }
    }
  },
  redemptions: {
    id: 'redemptions',
    navLabel: 'Redemptions',
    title: 'Redemptions',
    items: {
      howDoRedemptionTokensWork: {
        id: 'what-are-redemption-tokens-and-how-do-they-work',
        title: 'What are redemption tokens and how do they work?',
        answer: (
          <>
            <p className="mb-2">
              Redemption Tokens are digital collectibles that are used to redeem
              for a physical collectible corresponding to the Mythic, Grail,
              Legendary, Ultra, and/or Royalty Set(s) for any given collection
              held in your inventory.
            </p>
            <p>
              Redemption Tokens are delivered to your inventory after a
              redemption snapshot occurs. To redeem a token, visit the{' '}
              <Link
                target="_blank"
                href="/redemptions/"
                className="inline-link"
                rel="noreferrer"
              >
                Redemptions
              </Link>{' '}
              page to claim your physical collectible before the Redemption
              window ends.
            </p>
          </>
        )
      },
      whatAreCollectionSnapshots: {
        id: 'what-are-redemption-snapshots-and-when-are-they-taken',
        title: 'What are redemption snapshots and when are they taken?',
        answer: (
          <>
            <p className="mb-2">
              A redemption snapshot gives us the ability to see a complete list
              of all accounts at a given time that hold specific digital
              collectibles for a particular drop.
            </p>
            <p className="mb-2">
              We take a snapshot to find out which accounts hold a Mythic,
              Grail, Legendary, Ultra, and/or Royalty Set(s) so that we may then
              send Redemption Tokens to those owners.
            </p>
            <p>
              Snapshots are taken at exactly 11AM PT on the snapshot date
              provided on the drop page. We advise not opening packs or
              transferring items for at least one hour before or after the
              snapshot time to be certain they are included in the snapshot.
            </p>
          </>
        )
      },
      doYouNeedToHoldYourAssetsExclusivelyInDropppToReceiveARedemptionToken: {
        id: 'do-you-need-to-hold-your-grail-legendary-or-royalty-set-exclusively-in-your-droppp-account-to-receive-a-redemption-token',
        title:
          'Do you need to hold your Mythic, Grail, Legendary, Ultra, or Royalty Set(s) exclusively in your Droppp account to receive a Redemption Token?',
        answer: (
          <p>
            When we take snapshots, any WAX Blockchain wallet that holds the
            Mythic, Grail, Legendary, Ultra, or Royalty Set(s) will receive a
            Redemption Token.{' '}
            <b className="text-white">
              It’s important to note you must hold all Royalty Set items in the
              same wallet/account to be rewarded with a Royalty Redemption Token
            </b>
            .
          </p>
        )
      },
      whatIsARoyaltySet: {
        id: 'what-is-a-royalty-set',
        title: 'What is a Royalty Set?',
        answer: (
          <p>
            A Royalty Set is every single digital collectible that includes
            Common, Uncommon, Rare and Epic rarities of a specific collection.
            You can use the{' '}
            <Link
              href="/collection-tracker"
              className="inline-link"
              rel="noreferrer"
              target="_blank"
            >
              Collection Tracker
            </Link>{' '}
            to help assist you in completing your Royalty Set(s).
          </p>
        )
      },
      whatDoDiamondsMean: {
        id: 'what-do-the-diamonds-mean',
        title: 'What do the Diamonds mean?',
        answer: (
          <div>
            Diamonds <Icon name="diamond" className="inline text-white" />{' '}
            indicate that a particular digital collectible will be redeemable
            for a physical item. As long as you hold this digital collectible
            when the{' '}
            <Link
              href="/faq?question=what-are-redemption-snapshots-and-when-are-they-taken"
              className="inline-link"
              rel="noreferrer"
              target="_blank"
            >
              snapshot
            </Link>{' '}
            is taken, you will receive a Redemption Token that can be redeemed
            for a physical collectible.
          </div>
        )
      },
      howCanIGetAPhysicalCollectible: {
        id: 'how-can-i-get-a-physical-collectible',
        title: 'How can I get a physical collectible?',
        answer: (
          <p>
            With most Funko Digital Pop!™ drops you have the opportunity to
            collect a physical collectible. By collecting digital collectibles
            of certain rarities (including Mythic, Grail, Legendary, Ultra, or
            completing a full Royalty Set), you will receive a Redemption
            Token(s) that can be redeemed for a physical item.
          </p>
        )
      },
      redemptionCollectionEligibility: {
        id: 'how-do-i-know-if-the-digital-collectible-i-would-like-to-purchase-will-be-eligible-for-a-redemption-token-and-then-a-physical-collectible',
        title:
          'How do I know if the digital collectible I would like to purchase will be eligible for a Redemption Token and then a physical collectible?',
        answer: (
          <div>
            <span>
              Keep an eye out for a diamond next to a rarity badge like{' '}
            </span>
            <RarityBadge
              rarity={RARITY_TYPES.Legendary}
              className="inline"
              redeemable={true}
            />
            <span>. If you see a diamond </span>
            <Icon name="diamond" className="inline text-white" />
            <span>
              , this means the digital collectible is still eligible for a
              Redemption Token which can later be redeemed for a physical
              collectible.
            </span>
          </div>
        )
      },
      redemptionTokenEligibility: {
        id: 'how-do-i-know-if-the-redemption-token-i-would-like-to-purchase-will-be-eligible-to-redeem-for-a-physical-collectible',
        title:
          'How do I know if the Redemption Token I would like to purchase will be eligible to redeem for a physical collectible?',
        answer: (
          <div>
            <div className="mb-2">
              <span>The Redemption Token will have a redeemable badge </span>
              <RarityBadge
                rarity={RARITY_TYPES.Redeemable}
                className="inline text-white"
                redeemable={true}
              />
              .{' '}
              <span>
                If you see this, it indicates that the token can be redeemed for
                a physical collectible.
              </span>
            </div>
            <div>
              <span>
                Otherwise, the Redemption Token will show an expired badge{' '}
              </span>
              <RarityBadge
                rarity={RARITY_TYPES.Redeemable}
                className="inline"
                redeemable={false}
              />
              <span>
                {' '}
                which indicates that it is no longer eligible to be redeemed for
                a physical collectible. Droppp Marketplace will warn you if
                you're trying to purchase a Redemption Token that is expired.
              </span>
            </div>
          </div>
        )
      },
      physicalCollectibleRedemption: {
        id: 'do-i-need-to-transfer-my-redemption-token-to-droppp-in-order-to-redeem-for-the-physical-collectible',
        title:
          'Do I need to transfer my Redemption Token to Droppp in order to redeem for the physical collectible?',
        answer: (
          <p>
            If you collect exclusively on Droppp, your Redemption Token will be
            automatically available in your inventory after the snapshot. If you
            received a Redemption Token in an external wallet, you must transfer
            it to Droppp and redeem the token for the respective physical
            collectible before the Redemption Token expires.
          </p>
        )
      },
      redemptionExpiration: {
        id: 'do-redemption-tokens-expire',
        title: 'Do redemption tokens expire?',
        answer: (
          <p>
            Yes, Redemption Tokens expire 30 days after the Tokens drop
            following a snapshot.{' '}
            <b className="text-white">
              Once Redemption Tokens expire, they are no longer eligible to
              redeem for a physical collectible.
            </b>{' '}
            Redemption Tokens will remain in your wallet unless you sell or
            transfer them. Keep track of all current and upcoming Redemptions on
            the{' '}
            <Link
              href="/redemptions"
              className="inline-link"
              rel="noreferrer"
              target="_blank"
            >
              Redemptions
            </Link>{' '}
            page.
          </p>
        )
      },
      redemptionUVPremium: {
        id: 'are-uv-premium-pop-protectors-availble-for-purchase-when-redeeming-redemption-tokens-on-droppp',
        title:
          'Are UV Premium Pop! Protectors available for purchase when redeeming Redemption Tokens on Droppp?',
        answer: (
          <p>
            Yes, UV Premium Pop! Protectors are available for purchase when
            redeeming Redemption Tokens on Droppp. The optional UV Premium Pop!
            Protectors cost $8 per Pop! and are only available for standard size
            Pop! (4" size Pop!). UV Premium Pop! Protectors are not available
            for Pop! Deluxe (6" size Pop!) and larger items. We are unable to
            add protectors once your redemption order is complete.
          </p>
        )
      },
      redemptionReplacementPolicy: {
        id: 'what-is-the-replacement-policy-for-digital-pop-vinyl-figures',
        title: 'What is the Replacement Policy for Digital Pop! Vinyl Figures?',
        answer: (
          <p>
            We hope your order arrives to you safe and sound. However, if
            there’s any issues with the item(s) you received, please{' '}
            <a href="mailto:support@droppp.io" className="inline-link">
              contact support
            </a>{' '}
            as soon as possible within 30 days of receipt. Please include your
            Droppp account and order number. Please see{' '}
            <Link
              href="https://funko.com/replacement-refund-policy/replacement-refund-policy.html"
              className="inline-link"
              rel="noreferrer"
              target="_blank"
            >
              Funko's full Replacement Policy
            </Link>{' '}
            for further details.
          </p>
        )
      }
    }
  },
  shipping: {
    navLabel: 'Shipping',
    id: 'shipping',
    title: 'Shipping',
    items: {
      fees: {
        id: 'do-i-have-to-pay-shipping-fees',
        title: 'Do I have to pay shipping fees?',
        answer: (
          <p>
            Shipping costs will apply for both U.S. and international orders.
            Orders will be charged at a flat rate per individual item for both
            U.S. domestic and international orders. For Funko Digital Pop!™
            Redemptions, orders of 1 item or more qualify for a $5 USD shipping
            discount per Pop! Discount will be applied at check out.
          </p>
        )
      },
      shippingTimeline: {
        id: 'how-long-will-it-take-to-receive-my-physical-collectible',
        title: 'How long will it take to receive my physical collectible?',
        answer: (
          <p>
            Check the important dates section at the bottom of the drop landing
            page on{' '}
            <a
              href="https://digital.funko.com/"
              className="inline-link"
              rel="noreferrer"
              target="_blank"
            >
              digital.funko.com
            </a>{' '}
            for the estimated shipping quarter. Shipping dates are subject to
            change based on global shipping and port delays.
          </p>
        )
      },
      noTrackingUpdates: {
        id: 'why-am-i-not-seeing-any-tracking-updates-on-my-physical-collectible-order',
        title:
          'Why am I not seeing any tracking updates on my physical collectible order?',
        answer: (
          <p>
            Tracking updates typically start appearing within 2-3 business days
            from the time you receive your shipment confirmation email, though
            in some cases it may take up to 5 business days. If by this time you
            do not see updates, contact{' '}
            <a href="mailto:support@droppp.io" className="inline-link">
              support@droppp.io
            </a>
            .
          </p>
        )
      },
      physicalCollectibleQuestions: {
        id: 'i-have-question-about-my-physical-collectible-order-who-should-i-reach-out-to',
        title:
          'I have questions about my physical collectible order–who should I reach out to?',
        answer: (
          <p>
            For questions related to your physical collectible order, contact
            Droppp Support at{' '}
            <a href="mailto:support@droppp.io" className="inline-link">
              support@droppp.io
            </a>
            .
          </p>
        )
      }
    }
  },
  misc: {
    id: 'misc',
    navLabel: 'Miscellaneous',

    title: 'MISCELLANEOUS',
    items: {
      purchaseRamOrCpu: {
        id: 'do-i-need-to-buy-or-stake-resources-like-ram-or-cpu-to-use-my-droppp-account',
        title:
          'Do I need to buy or stake resources like RAM or CPU to use my Droppp account?',
        answer: (
          <p>
            No, all WAX blockchain resources are completely managed by Droppp
            for your benefit.
          </p>
        )
      },
      canITransferToSellElsewhere: {
        id: 'can-i-transfer-items-out-of-droppp-to-sell-on-other-marketplaces',
        title:
          'Can I transfer items out of Droppp to sell on other marketplaces?',
        answer: (
          <p>
            <span>
              Yes, you can transfer items to other wallets on the WAX blockchain
              from your Droppp account to sell on other marketplaces.
            </span>{' '}
            {/* <span className="text-alert">
              Please keep in mind any “Droppp Exclusive” assets can only be
              transferred to other Droppp accounts.
            </span> */}
          </p>
        )
      }
    }
  }
}

export default FAQ_QUESTIONS
