import React from 'react'
import moment from 'moment'
import { formattedCountdown } from '@/util/time'
import { MergedDrop } from '@/config/drops/schema'
import Icon from '../Icon'
import { isRewardsDrop } from '@/util/dropHelpers'

interface Props {
  drop: MergedDrop
  timeRemainingInSeconds?: number
  buttonActivationInSecondsBeforeDrop?: number
  children?: (
    view: string | React.JSX.Element,
    disabled: boolean
  ) => React.JSX.Element
}

const CountdownTimeDisplay = ({
  drop,
  timeRemainingInSeconds = 0,
  buttonActivationInSecondsBeforeDrop = 0,
  children
}: Props) => {
  const durationUntilLaunch = moment.duration(timeRemainingInSeconds, 'seconds')
  const durationBeforeButtonActivation = moment.duration(
    buttonActivationInSecondsBeforeDrop,
    'seconds'
  )
  const launchTime = moment().add(durationUntilLaunch).local()

  const view = render(
    drop,
    drop.state,
    launchTime,
    durationUntilLaunch,
    durationBeforeButtonActivation
  )
  const disabled =
    dropHasFinished(drop.state) ||
    durationUntilLaunch.asSeconds() > durationBeforeButtonActivation.asSeconds()

  if (typeof children == 'function') {
    return children(view, disabled)
  }

  return <>{view}</>
}

const render = (
  drop,
  state,
  launchDate,
  durationUntilLaunch,
  durationBeforeButtonActivation
) => {
  if (!durationUntilLaunch.isValid()) {
    return 'Invalid Duration'
  }

  if (dropHasFinished(state)) {
    return formattedState(state)
  }

  if (durationUntilLaunch.asSeconds() > 0) {
    if (durationUntilLaunch.asHours() > 24) {
      return `Purchase on ${launchDate.format('MMM D')}`
    } else if (
      durationUntilLaunch.asSeconds() >
      durationBeforeButtonActivation.asSeconds()
    ) {
      if (isRewardsDrop(drop)) {
        return `Get Packs in ${formattedCountdown(durationUntilLaunch)}`
      } else {
        return `Join Queue in ${formattedCountdown(durationUntilLaunch)}`
      }
    }
    return isRewardsDrop(drop) ? (
      <>
        <Icon name="crown" className="mr-1" />
        Get Packs
      </>
    ) : (
      'Join Queue'
    )
  }

  return isRewardsDrop(drop) ? (
    <>
      <Icon name="crown" className="mr-1" />
      Get Packs
    </>
  ) : (
    'Join Queue'
  )
}

const dropHasFinished = state => {
  return state == 'sold_out' || state == 'ended'
}

const formattedState = state => {
  switch (state) {
    case 'sold_out':
      return 'Sold Out'
    case 'ended':
      return 'Sale Ended'
    default:
      return '(Unsupported State)'
  }
}

export default CountdownTimeDisplay
