import { DropState } from '@/enum'
import { DateFormatter, isOnOrBeforeNow } from '@/util/time'
import {
  calcTimeRemaining,
  initialPackSaleEndedWithinSevenDays,
  queueOpen
} from '@/util/timeHelpers'
import { CountdownContext } from '@/contexts/countdown'
import DropAvailability from '../DropAvailability'
import { formattedPacificDate } from '@/util/time/pt'
import { useDropContext } from '@/features/drop/DropContextProvider'
import { isRewardsDrop } from '@/util/dropHelpers'

const DropAvailDate = () => {
  const { drop, state, time_launch, redeem_start_date } = useDropContext()
  // Let's be very specific about the drop states where we don't want an availability date shown.
  switch (drop.state) {
    case DropState.SaleContinued:
      return null
    default:
      break
  }

  if (isRewardsDrop(drop) && isOnOrBeforeNow(drop.time_launch)) {
    return null
  }

  const remaining = calcTimeRemaining(time_launch)

  if (!queueOpen(remaining) && !isRewardsDrop(drop)) {
    return (
      <CountdownContext.Consumer>
        {value => {
          if (!queueOpen(Number(value))) {
            return (
              <span className="inline">
                <DropAvailability drop={drop} time_remaining={value}>
                  {message => message}
                </DropAvailability>
              </span>
            )
          } else {
            return null
          }
        }}
      </CountdownContext.Consumer>
    )
  } else if (
    [DropState.SaleEnded, DropState.SoldOut].includes(state as DropState) &&
    initialPackSaleEndedWithinSevenDays(time_launch)
  ) {
    return (
      <span className="whitespace-nowrap">
        Collect by{' '}
        {formattedPacificDate(
          redeem_start_date,
          DateFormatter.LongHoursMinutes,
          true
        )}
      </span>
    )
  } else {
    return null
  }
}

export default DropAvailDate
