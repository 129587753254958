import { MODAL_ID } from '@/constants/modalId'
import NiceModal from '@ebay/nice-modal-react'
import Web3TransactionModal, { Web3Transaction } from '.'
import Button from '@/components/Button'
import { ControlledConfirmation } from '@/components/AcknowledgeBox/Confirmation'
import Header from './components/Header'
import Amount from './components/Amount'
import Parties, { TransactionParty } from './components/Parties'
import LineItem from './components/LineItem'
import { useForm } from 'react-hook-form'
import { ModalChildrenWrappers } from '../BaseModal'
import { useCountdown } from '@/hooks/useCountdown'

export type PurchaseIntent = {
  amount: number
  buyer: TransactionParty
  seller: TransactionParty
  expiresSeconds: number
  autoApprove: boolean
}

interface Web3Purchase extends Web3Transaction {
  purchaseIntent: PurchaseIntent
  submitApprovedPurchase: (autoApprove: boolean) => Promise<void>
  onCancel: () => void
}

interface PurchaseFormProps extends ModalChildrenWrappers, Web3Purchase {}

type FormData = {
  autoApprove: boolean
}

const PurchaseForm = ({
  ModalBody,
  ModalFooter,
  purchaseIntent,
  submitApprovedPurchase,
  onCancel
}: PurchaseFormProps) => {
  const { amount, buyer, seller, expiresSeconds, autoApprove } = purchaseIntent
  const remaining = useCountdown(expiresSeconds, onCancel)

  const {
    handleSubmit,
    control,
    formState: { isSubmitting }
  } = useForm<FormData>({
    defaultValues: {
      autoApprove
    }
  })

  const handleSubmitForm = handleSubmit(
    async formData => await submitApprovedPurchase(formData.autoApprove)
  )

  return (
    <form onSubmit={handleSubmitForm}>
      <ModalBody>
        <div className="flex flex-col gap-3">
          <Header description="Please approve the request to complete the sale." />
          <div className="space-y-[10px]">
            <Amount amount={amount} />
            <Parties parties={{ from: buyer, to: seller }} />
            <LineItem property="Time Remaining" value={remaining} />
          </div>
          <ControlledConfirmation
            control={control}
            name="autoApprove"
            className="bg-gray-800"
          >
            Auto-approve transactions for the next 20 minutes.
          </ControlledConfirmation>
        </div>
      </ModalBody>
      <ModalFooter className="space-y-2 max-md:pb-2">
        <Button
          type="submit"
          className="w-full"
          theme="solana"
          loading={isSubmitting}
        >
          Approve Purchase
        </Button>
        <p className="text-gray-400 body-xs text-center text-balance">
          A portion of this sale will be paid to Droppp. Remaining balance is
          paid to seller.
        </p>
      </ModalFooter>
    </form>
  )
}

const Web3PurchaseApproval = NiceModal.create<Web3Purchase>(modalProps => {
  return (
    <Web3TransactionModal id={MODAL_ID.web3.approvePurchase}>
      {layoutProps => <PurchaseForm {...layoutProps} {...modalProps} />}
    </Web3TransactionModal>
  )
})

export default Web3PurchaseApproval
