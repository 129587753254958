import { Toast } from '@/components'
import WalletCard, { WalletCardType } from '@/components/WalletCard'
import { useWallet } from '@/features/wallet/providers/wallet'
import { ButtonLink } from '@/components/Button'

export const MigrationBodyComponent = () => {
  const { balance, hasDeposited } = useWallet()

  return (
    <div className="space-y-3">
      <ButtonLink
        href="/bloggg/important-updates-and-changes-coming/"
        theme="clean-blue"
      >
        Why is this happening?
      </ButtonLink>
      {hasDeposited && (
        <Toast inline type="attention">
          Do not send funds to your Droppp Balance. Polygon and Ethereum
          networks are no longer supported, and any funds sent will be lost and
          unrecoverable.
        </Toast>
      )}
      <WalletCard type={WalletCardType.Inactive} balance={balance} />
    </div>
  )
}
