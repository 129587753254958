import post from '@/api/core/http/post'
import { buildApiUrl } from '@/api/core/url'
import { PurchaseListingInput } from '@/api/resources/user/listing/purchase/shared'
import {
  PurchaseListingCompleteResponse,
  purchaseListingCompleteResponseSchema
} from './schema'
import { AuthMetaType } from '@/api/core/http/types'

export const path = `/user/listing/purchase/complete`

export const purchaseListingComplete = async (
  input: PurchaseListingInput,
  meta: AuthMetaType
): Promise<PurchaseListingCompleteResponse> =>
  post<PurchaseListingCompleteResponse>(
    buildApiUrl(path, input),
    { meta },
    purchaseListingCompleteResponseSchema
  )
