import bs58 from 'bs58'
import * as SolanaWeb3 from '@solana/web3.js'
import * as SolanaSPL from '@solana/spl-token'
import { walletPublicConfig } from '@/config/wallet'

const SOL_USDC_TOKEN = new SolanaWeb3.PublicKey(walletPublicConfig.solUsdcToken)

export const SOL_DP_ADDRESS = new SolanaWeb3.PublicKey(
  walletPublicConfig.solDpAddress
)

export const SOL_USDC_DP_ADDRESS = new SolanaWeb3.PublicKey(
  walletPublicConfig.solDpUsdcAddress
)

export type UsdcAccount = {
  ownerAddress: SolanaWeb3.PublicKey
  tokenAddress: SolanaWeb3.PublicKey
}

export type UsdcRecipient = UsdcAccount & {
  exists: boolean
}

export const TRANSFER_OPCODE = 0x03

export const toPublicKey = (address: string) =>
  new SolanaWeb3.PublicKey(address)

export const getUsdcAccount = (
  address: string | SolanaWeb3.PublicKey
): UsdcAccount => {
  const ownerAddress = new SolanaWeb3.PublicKey(address)
  const tokenAddress = SolanaSPL.getAssociatedTokenAddressSync(
    SOL_USDC_TOKEN,
    ownerAddress
  )

  return {
    ownerAddress,
    tokenAddress
  }
}

const CENTI_CENTS_FACTOR = 1000000

export const buildWithdrawal = (
  source: UsdcAccount,
  destination: UsdcRecipient,
  blockhash: string,
  amount: number,
  fee: number,
  priorityRate: number
) => {
  const withdrawalTransaction = new SolanaWeb3.Transaction()

  if (!destination.exists) {
    const createTokenInstruction =
      SolanaSPL.createAssociatedTokenAccountInstruction(
        SOL_DP_ADDRESS,
        destination.tokenAddress,
        destination.ownerAddress,
        SOL_USDC_TOKEN
      )
    withdrawalTransaction.add(createTokenInstruction)
  }

  const netAmountCentiCents = toCentiCents(amount - fee)

  const transferInstruction = SolanaSPL.createTransferInstruction(
    source.tokenAddress,
    destination.tokenAddress,
    source.ownerAddress,
    netAmountCentiCents
  )

  withdrawalTransaction.add(transferInstruction)

  const feeCentiCents = toCentiCents(fee)

  const feeInstruction = SolanaSPL.createTransferInstruction(
    source.tokenAddress,
    SOL_USDC_DP_ADDRESS,
    source.ownerAddress,
    feeCentiCents
  )

  withdrawalTransaction.add(feeInstruction)

  const priorityRateInstruction =
    SolanaWeb3.ComputeBudgetProgram.setComputeUnitPrice({
      microLamports: priorityRate
    })

  withdrawalTransaction.add(priorityRateInstruction)

  withdrawalTransaction.feePayer = SOL_DP_ADDRESS
  withdrawalTransaction.recentBlockhash = blockhash

  return withdrawalTransaction
}

export const buildPurchase = (
  buyer: UsdcAccount,
  seller: UsdcRecipient,
  blockhash: string,
  netAmount: number,
  netFee: number,
  priorityRate: number
) => {
  const purchaseTransaction = new SolanaWeb3.Transaction()

  if (!seller.exists) {
    const createTokenInstruction =
      SolanaSPL.createAssociatedTokenAccountInstruction(
        SOL_DP_ADDRESS,
        seller.tokenAddress,
        seller.ownerAddress,
        SOL_USDC_TOKEN
      )
    purchaseTransaction.add(createTokenInstruction)
  }

  const netAmountCentiCents = toCentiCents(netAmount)

  const purchaseInstruction = SolanaSPL.createTransferInstruction(
    buyer.tokenAddress,
    seller.tokenAddress,
    buyer.ownerAddress,
    netAmountCentiCents
  )

  purchaseTransaction.add(purchaseInstruction)

  const feeCentiCents = toCentiCents(netFee)

  const feeInstruction = SolanaSPL.createTransferInstruction(
    buyer.tokenAddress,
    SOL_USDC_DP_ADDRESS,
    buyer.ownerAddress,
    feeCentiCents
  )

  purchaseTransaction.add(feeInstruction)

  const priorityRateInstruction =
    SolanaWeb3.ComputeBudgetProgram.setComputeUnitPrice({
      microLamports: priorityRate
    })

  purchaseTransaction.add(priorityRateInstruction)

  purchaseTransaction.feePayer = SOL_DP_ADDRESS
  purchaseTransaction.recentBlockhash = blockhash

  return purchaseTransaction
}

export const toCentiCents = (value: number) =>
  Math.trunc(value * CENTI_CENTS_FACTOR)

const DISPLAY_ADDRESS_LENGTH = 10

export const getDisplayAddress = (address: string) => {
  if (address.length <= DISPLAY_ADDRESS_LENGTH) return address
  return `${address.slice(0, 6)}...${address.slice(-6)}`
}

export const getTransferInstructions = (
  transaction: SolanaWeb3.Transaction
): SolanaWeb3.TransactionInstruction[] =>
  transaction.instructions.filter(
    instruction =>
      instruction.programId.equals(SolanaSPL.TOKEN_PROGRAM_ID) &&
      instruction.data[0] === TRANSFER_OPCODE
  )

export const encodeTransaction = (txn: SolanaWeb3.Transaction) =>
  bs58.encode(new Uint8Array(txn.serialize({ requireAllSignatures: false })))

export const decodeTransaction = (
  encodedTransaction: string
): SolanaWeb3.Transaction =>
  SolanaWeb3.Transaction.from(bs58.decode(encodedTransaction))

export const encodeSignature = (txn: SolanaWeb3.Transaction) =>
  bs58.encode(txn.signature)
