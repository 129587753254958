import {
  RegisteredWeb3Wallet,
  Web3Wallet
} from '@/api/resources/user/web3/shared'
import IdentityVerificationState from '@/types/identityVerificationState'

export type Web3WalletStatus = 'none' | 'connected' | 'disconnected'

type StatusReasonMapping = {
  disconnected: 'isNotStored' | 'doesNotMatch'
}

// These may not be the best terms for each status - Josh Dobson - 10/31/24
export type UserStatus =
  | 'unverified'
  | 'verifiedNoWallet'
  | 'canMigrate'
  | 'startedMigration'

export type Web3WalletStatusReason =
  | StatusReasonMapping['disconnected']
  | undefined

export type AccountWallet = {
  status: Web3WalletStatus
  statusReason?: Web3WalletStatusReason
  wallet: Web3Wallet
}

export type ConnectedWallet = Pick<RegisteredWeb3Wallet, 'tk_suborg_id'> & {
  tk_credential_id: string
  connected_at: string
  auto_approve: boolean
}

export type WalletContextType = {
  isWalletLoading: boolean
  isNotSignedIn: boolean
  isKycVerified: boolean
  isNotKycVerified: boolean
  notEnoughFunds: (price: number) => boolean
  wallet: Web3Wallet | null
  status: Web3WalletStatus
  balance: number | null
  withdrawalFee: number | null
  identityVerification?: IdentityVerificationState
  verifyIdPrompted: boolean
  hasDeposited: boolean
  migrationNeeded: boolean
  migrationStep: number
  statusReason?: Web3WalletStatusReason
  registerWallet: () => Promise<void>
  reconnectWallet: () => Promise<void>
  startWalletRecovery: () => Promise<void>
  finishWalletRecovery: (credentialBundle: string) => Promise<void>
  getCredentials: () => Promise<string>
  resolveCredentials: () => Promise<string>
  isAutoApproveEnabled: boolean
  setAutoApproveEnabled: (enabled: boolean) => void
  exportWallet: () => Promise<boolean>
  updateRecoveryEmail: (email: string) => Promise<void>
  logoutWallet: () => void
}

export class WalletError extends Error {
  cause?: Error

  constructor(cause?: Error) {
    super(cause?.message)
    this.cause = cause
  }
}

export class PasskeyCreationError extends WalletError {}
export class InvalidRecoveryCodeError extends WalletError {}
export class InvalidPasskeyError extends WalletError {}
export class ExportWalletError extends WalletError {}
export class UpdateRecoveryEmailError extends WalletError {}
