import React from 'react'
import FluidContainer from '@/components/FluidContainer'
import SeriesBadge from '@/components/SeriesBadge'
import CloudinaryImage from '@/components/CloudinaryImage'
import { SOCIAL_LINKS } from '@/constants'
import CircleIconLink from '@/components/CircleIconLink'
import useBreakpoints from '@/hooks/useBreakpoints'
import { DROP_BLADE_GRADIENTS, DROP_THEME } from '@/constants/drops'
import DropHeroLogos from './DropHeroLogos'
import classNames from 'classnames'
import CloudinaryAsset from '@/components/CloudinaryAsset'
import DropHeroGradient from './DropHeroGradient'
import { CLOUDINARY_DEFAULT_IMAGE_QUALITY } from '@/constants/cloudinary'
import NotifyMeAction from '@/components/Actions/NotifyMeAction'
import DropHeroDescription from './DropHeroDescription'
import DropButtons from './DropButtons'
import { useDropContext } from '@/features/drop/DropContextProvider'
import dynamic from 'next/dynamic'
import { isRewardsDrop } from '@/util/dropHelpers'

const DropTimerProvider = dynamic(
  () => import('../../features/drop/DropTimer'),
  {
    ssr: false
  }
)

const DropStandardHero = () => {
  const { isMobile, isMedium } = useBreakpoints()
  const { drop } = useDropContext()
  const {
    cloudinaryFolder,
    dropType,
    heroBg,
    type,
    mockName,
    figures: { quality = 75, blade },
    heroGradient = DROP_BLADE_GRADIENTS,
    discordUrl,
    heroLogo,
    heroLogo2,
    theme,
    heroBgQuality = CLOUDINARY_DEFAULT_IMAGE_QUALITY
  } = drop

  // DEFAULT VALUES
  // @TODO: Clean up these default values -  Josh Dobson
  let figureWidth = 1032
  let figureHeight = 750
  let maxFigureWidth = 515
  let minFigureWidth = 0

  if (blade.dimensions) {
    if (blade.dimensions.minWidth) {
      if (typeof blade.dimensions.minWidth !== 'number') {
        minFigureWidth = isMedium
          ? blade.dimensions.minWidth.lg
          : blade.dimensions.minWidth.sm
      } else {
        minFigureWidth = blade.dimensions.minWidth
      }
    }

    if (blade.dimensions.maxWidth) {
      if (typeof blade.dimensions.maxWidth !== 'number') {
        maxFigureWidth = isMedium
          ? blade.dimensions.maxWidth.lg
          : blade.dimensions.maxWidth.sm
      } else {
        maxFigureWidth = blade.dimensions.maxWidth
      }
    }

    if (blade.dimensions.width) {
      if (typeof blade.dimensions.width !== 'number') {
        figureWidth = isMedium
          ? blade.dimensions.width.lg
          : blade.dimensions.width.sm
      } else {
        figureWidth = blade.dimensions.width
      }
    }

    if (blade.dimensions.height) {
      if (typeof blade.dimensions.height !== 'number') {
        figureHeight = isMedium
          ? blade.dimensions.height.lg
          : blade.dimensions.height.sm
      } else {
        figureHeight = blade.dimensions.height
      }
    }
  }

  const cloudinaryAssetPath = `drops/${cloudinaryFolder}/`
  const isLightTheme = theme === DROP_THEME.LIGHT
  const bgAndTextClassess = isLightTheme ? 'bg-gray-700/70 text-white' : ''
  const buttonTheme = isLightTheme ? 'black' : 'white'
  const isRewards = isRewardsDrop(drop)

  return (
    <div
      className="relative overflow-hidden"
      style={{ paddingTop: 'var(--headerHeight)' }}
    >
      {!isLightTheme && (
        <DropHeroGradient
          gradient={isMobile ? heroGradient.mobile : heroGradient.desktop}
        />
      )}
      <CloudinaryImage
        path={cloudinaryAssetPath}
        imageId={heroBg}
        layout="fill"
        objectFit="cover"
        alt="Drop Background"
        transformations={{ quality: heroBgQuality }}
        className="-z-10"
      />
      <div
        className={classNames('max-w-[1600px] mx-auto relative', {
          'text-gray-900': isLightTheme
        })}
      >
        <FluidContainer
          targetWidth={832}
          className="relative flex flex-col items-center justify-center text-center pt-8 mx-auto px-3 max-w-[928px]"
        >
          <>
            <DropHeroLogos
              dropType={dropType}
              imgPath={cloudinaryAssetPath}
              logo1={heroLogo}
              logo2={heroLogo2}
              exclusive={drop.exclusive}
              theme={theme}
              className={isRewards ? 'max-sm:mb-4' : 'mb-3'}
            />
            <SeriesBadge fluid label={type} className="mb-f2" />
            <h2
              className={classNames('h2 md:h1 mb-2', {
                'md:!h2': drop.exclusive
              })}
            >
              <span dangerouslySetInnerHTML={{ __html: mockName }} />
            </h2>
            <DropHeroDescription />
            {drop.state == 'lite_ultra' && (
              <>
                <div className="w-full flex flex-wrap justify-center items-center gap-2">
                  <NotifyMeAction dropId={drop.id} buttonTheme={buttonTheme} />
                  <div className="flex gap-2">
                    <CircleIconLink
                      name="discord"
                      url={discordUrl}
                      className={bgAndTextClassess}
                    />
                    <CircleIconLink
                      name="twitter"
                      url={SOCIAL_LINKS.twitter}
                      className={bgAndTextClassess}
                    />
                    <CircleIconLink
                      name="instagram"
                      url={SOCIAL_LINKS.instagram}
                      className={bgAndTextClassess}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        </FluidContainer>
        <DropTimerProvider drop={drop}>
          <DropButtons />
        </DropTimerProvider>

        <div
          style={{ maxWidth: `${maxFigureWidth}px` }}
          className="mx-auto mt-4"
        >
          <div
            className="relative left-1/2 -translate-x-1/2"
            style={{
              aspectRatio: `${figureWidth}/${figureHeight}`,
              minWidth: `${minFigureWidth}px`
            }}
          >
            <CloudinaryAsset
              cloudinaryFolder={cloudinaryAssetPath}
              asset={blade}
              className="absolute top-0 bottom-[-2px] right-0 left-0 min-w-[100%] min-h-[100%] max-w-[100%] max-h-[100%] m-auto object-contain object-bottom"
              transformations={{
                quality: quality,
                resize: { width: figureWidth }
              }}
            />
          </div>
        </div>
        {/* Adding a bottom-[-2px] is necessary because at certain browser widths a 1px line shows up below the video */}
      </div>
    </div>
  )
}

export default DropStandardHero
