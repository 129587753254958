import DropHeroGradient from './DropHeroGradient'
import CloudinaryImage from '../CloudinaryImage'
import { DROP_BLADE_GRADIENTS, DROP_THEME } from '@/constants/drops'
import useBreakpoints from '@/hooks/useBreakpoints'
import classNames from 'classnames'
import SimpleFluidContainer from '../FluidContainer/SimpleFluidContainer'
import DropHeroLogos from './DropHeroLogos'
import DropDescription from './DropHeroDescription'
import NotifyMeAction from '../Actions/NotifyMeAction'
import CircleIconLink from '../CircleIconLink'
import { SOCIAL_LINKS } from '@/constants'
import DropButtons from './DropButtons'
import { CLOUDINARY_DEFAULT_IMAGE_QUALITY } from '@/constants/cloudinary'
import CloudinaryAsset from '../CloudinaryAsset'
import { useDropContext } from '@/features/drop/DropContextProvider'
import DropTimerProvider from '@/features/drop/DropTimer'

type Props = {
  spacing: boolean
}

const DropCondensedHero = ({ spacing = false }: Props) => {
  const { isMobile } = useBreakpoints()
  const { drop, state } = useDropContext()
  const {
    cloudinaryFolder,
    dropType,
    heroBg,
    dropHeaderSubtitle,
    figures: { quality = 75, blade },
    heroGradient = DROP_BLADE_GRADIENTS,
    discordUrl,
    heroLogo,
    heroLogo2,
    theme,
    heroBgQuality = CLOUDINARY_DEFAULT_IMAGE_QUALITY
  } = drop

  const cloudinaryAssetPath = `drops/${cloudinaryFolder}/`
  const isLightTheme = theme === DROP_THEME.LIGHT
  const bgAndTextClassess = isLightTheme ? 'bg-gray-700/70 text-white' : ''
  const buttonTheme = isLightTheme ? 'black' : 'white'

  return (
    <div
      className="relative overflow-hidden"
      style={{ paddingTop: spacing ? 'var(--headerHeight)' : '0' }}
    >
      {!isLightTheme && (
        <DropHeroGradient
          gradient={isMobile ? heroGradient.mobile : heroGradient.desktop}
        />
      )}
      <CloudinaryImage
        path={cloudinaryAssetPath}
        imageId={heroBg}
        layout="fill"
        objectFit="cover"
        alt="Drop Background"
        transformations={{ quality: heroBgQuality }}
        className="-z-10"
      />
      <div
        className={classNames('max-w-[1600px] mx-auto relative', {
          'text-gray-900': isLightTheme
        })}
      >
        <SimpleFluidContainer
          targetWidth={1248}
          className="container lg:aspect-[1440/400] flex flex-col lg:flex-row flex-1 items-center lg:gap-[8em]"
        >
          <>
            <div className="flex-1">
              <DropHeroLogos
                dropType={dropType}
                imgPath={cloudinaryAssetPath}
                logo1={heroLogo}
                logo2={heroLogo2}
                exclusive={drop.exclusive}
                theme={theme}
              />
              <h3 className={'h3 lg:text-[4em] mb-2'}>
                <span
                  dangerouslySetInnerHTML={{ __html: dropHeaderSubtitle }}
                />
              </h3>
              <DropDescription isCondensed />
              {state == 'lite_ultra' && (
                <>
                  <div className="w-full flex flex-wrap justify-center items-center gap-[2em]">
                    <NotifyMeAction
                      dropId={drop.id}
                      buttonTheme={buttonTheme}
                    />
                    <div className="flex gap-[2em]">
                      <CircleIconLink
                        name="discord"
                        url={discordUrl}
                        className={bgAndTextClassess}
                      />
                      <CircleIconLink
                        name="twitter"
                        url={SOCIAL_LINKS.twitter}
                        className={bgAndTextClassess}
                      />
                      <CircleIconLink
                        name="instagram"
                        url={SOCIAL_LINKS.instagram}
                        className={bgAndTextClassess}
                      />
                    </div>
                  </div>
                </>
              )}
              <DropTimerProvider drop={drop}>
                <DropButtons isCondensed />
              </DropTimerProvider>
            </div>
            <div className="relative max-w-[507px] flex-1 items-end">
              <CloudinaryAsset
                cloudinaryFolder={cloudinaryAssetPath}
                asset={blade}
                transformations={{
                  quality: quality
                }}
              />
            </div>
          </>
        </SimpleFluidContainer>
      </div>
    </div>
  )
}

export default DropCondensedHero
