import { z } from 'zod'
import {
  listingBalanceSchema,
  listingPurchaseSchema
} from '@/api/resources/user/listing/purchase/shared'

export const purchaseListingCompleteSchema = z.object({
  balance: listingBalanceSchema,
  listing: listingPurchaseSchema
})

export const purchaseListingCompleteResponseSchema =
  purchaseListingCompleteSchema

export type PurchaseListingCompleteResponse = z.infer<
  typeof purchaseListingCompleteResponseSchema
>
