import { useWallet } from '@/features/wallet/providers/wallet'
import { PurchaseIntent } from '@/components/Modals/ModalV2/Web3Transaction/PurchaseApproval'
import { hideModal, showModal } from '@/components/Modals/ModalV2'
import { MODAL_ID } from '@/constants/modalId'
import { useAuth } from '@/contexts/auth'
import {
  buildPurchase,
  encodeTransaction,
  getDisplayAddress,
  getUsdcAccount,
  toPublicKey,
  UsdcAccount,
  UsdcRecipient
} from '@/util/solanaHelper'
import { getSolanaLatestBlockhash } from '@/api/resources/solana/blockhash/latest'
import { getTransactionSigner } from '@/features/wallet/utils/turnkey'
import { submitWeb3Purchase } from '@/api/resources/user/web3/purchase'
import {
  ReservedListing,
  ReservedListingAddress
} from '@/api/resources/shared/listingReserve'
import { useCountdown } from '@/hooks/useCountdown'
import { checkSolanaAccountExists } from '@/api/resources/solana/account/exists'
import { notifyWalletError } from '@/features/wallet/utils/notify'

type UsePurchaseListingInput = {
  reservation: ReservedListing
}

type UsePurchaseListingResult = {
  submitPurchase: () => Promise<boolean>
}

type ReservedPurchase = {
  reservationId: string
  amount: number
  netAmount: number
  netFee: number
  priorityRate: number
  buyer: UsdcAccount
  seller: UsdcRecipient
}

const toUsdcAccount = (
  listingAddress: ReservedListingAddress
): UsdcAccount => ({
  ownerAddress: toPublicKey(listingAddress.address),
  tokenAddress: toPublicKey(listingAddress.associated_address)
})

const isUsdcAccountOnChain = async (usdcAccount: UsdcAccount) => {
  const checkResponse = await checkSolanaAccountExists({
    address: usdcAccount.tokenAddress.toString()
  })
  return checkResponse.exists
}

const resolveSeller = async (sellerAddress: ReservedListingAddress) => {
  const hasAssociatedUsdcAddress = !!sellerAddress.associated_address

  const sellerUsdcAccount = hasAssociatedUsdcAddress
    ? toUsdcAccount(sellerAddress)
    : getUsdcAccount(sellerAddress.address)

  if (hasAssociatedUsdcAddress) return { ...sellerUsdcAccount, exists: true }

  const accountExists = await isUsdcAccountOnChain(sellerUsdcAccount)
  return { ...sellerUsdcAccount, exists: accountExists }
}

export const usePurchaseListing = ({
  reservation
}: UsePurchaseListingInput): UsePurchaseListingResult => {
  const { user } = useAuth()
  const expiresSeconds = useCountdown(reservation.listing.timeout)

  const {
    wallet,
    getCredentials,
    resolveCredentials,
    isAutoApproveEnabled,
    setAutoApproveEnabled
  } = useWallet()

  const buildReservedPurchase = async (): Promise<ReservedPurchase> => {
    const {
      reservation_id,
      listing,
      buyer_address,
      seller_address,
      solana_priority_rate
    } = reservation

    const buyer = toUsdcAccount(buyer_address)
    const seller = await resolveSeller(seller_address)

    const amount = listing.listing_price
    const netFee = listing.net_fee
    const netAmount = listing.net_amount
    const priorityRate = solana_priority_rate

    return {
      reservationId: reservation_id,
      buyer,
      seller,
      amount,
      netFee,
      netAmount,
      priorityRate
    }
  }

  const submitReservedPurchase = async (
    credentialBundle: string,
    reservedPurchase: ReservedPurchase
  ) => {
    const { tk_suborg_id: organizationId } = wallet

    const { reservationId, netAmount, netFee, buyer, seller, priorityRate } =
      reservedPurchase

    const signer = await getTransactionSigner(organizationId, credentialBundle)

    const { blockhash } = await getSolanaLatestBlockhash()

    const purchaseTxn = buildPurchase(
      buyer,
      seller,
      blockhash,
      netAmount,
      netFee,
      priorityRate
    )

    await signer.addSignature(purchaseTxn, buyer.ownerAddress.toString())

    const encodedTransaction = encodeTransaction(purchaseTxn)

    await submitWeb3Purchase({ reservationId, transaction: encodedTransaction })
  }

  const approveReservedPurchase = async (
    reservedPurchase: ReservedPurchase
  ): Promise<boolean> => {
    const { amount, buyer, seller } = reservedPurchase

    const buyingParty = {
      address: getDisplayAddress(buyer.ownerAddress.toString()),
      avatarUrl: user?.avatar_media?.size0_url
    }

    const sellingParty = {
      address: getDisplayAddress(seller.ownerAddress.toString()),
      avatarUrl: reservation.seller?.media.avatar_media?.size0_url
    }

    const purchaseIntent: PurchaseIntent = {
      amount,
      buyer: buyingParty,
      seller: sellingParty,
      expiresSeconds,
      autoApprove: isAutoApproveEnabled
    }

    return new Promise(resolve => {
      const submitApprovedPurchase = async (autoApprove: boolean) => {
        try {
          const credentialBundle = await resolveCredentials()
          await submitReservedPurchase(credentialBundle, reservedPurchase)
          setAutoApproveEnabled(autoApprove)
          hideModal(MODAL_ID.web3.approvePurchase)
          resolve(true)
        } catch (error) {
          notifyWalletError(error)
        }
      }

      const handelCancel = () => {
        hideModal(MODAL_ID.web3.approvePurchase)
        resolve(false)
      }

      showModal(MODAL_ID.web3.approvePurchase, {
        purchaseIntent,
        submitApprovedPurchase,
        onCancel: handelCancel,
        overlayClassName: 'z-viewModal'
      })
    })
  }

  const submitPurchase = async (): Promise<boolean> => {
    const reservedPurchase = await buildReservedPurchase()
    const credentialBundle = isAutoApproveEnabled && (await getCredentials())

    if (credentialBundle) {
      await submitReservedPurchase(credentialBundle, reservedPurchase)
      return true
    }

    return await approveReservedPurchase(reservedPurchase)
  }

  return { submitPurchase }
}
